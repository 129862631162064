import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LanguageDirectionHandler = ({ children }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const isNewsDetailsPage = location.pathname.startsWith("/NewsDetails");

    // Apply direction change only if not on NewsDetails page
    if (!isNewsDetailsPage) {
      const dir = i18n.dir(i18n.language);
      document.documentElement.dir = dir;
      console.log("Direction changed to:", dir);
      console.log("Current language:", i18n.language);
    } else {
      // Keep the previous direction when on NewsDetails page
      const previousDir = document.documentElement.dir;
      console.log(
        "Keeping previous direction on NewsDetails page:",
        previousDir
      );
    }

    // No reload or state changes here to avoid loops
  }, [i18n.language, location]); // Only depend on i18n.language and location

  return children;
};

export default LanguageDirectionHandler;
