import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../components/texts.css'

const TextFieldMessage = ({size, inputPlaceholder, disableError}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setError(false);
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  const handleValidation = () => {
    if (inputValue.trim() === '') {
      if (disableError) {
        setError(true);
      }

    }
  };

  return (
    <div className={`text-area-message ${editing ? 'editing' : ''} ${error ? 'error' : ''}`}>
      <textarea
        placeholder={inputPlaceholder}
        className={`text-input-field-message ${size === "long" ? "long" : ""}`}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleValidation}
      />
    </div>
  );
};

// Declare propTypes
TextFieldMessage.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputPlaceholder: PropTypes.string,
  disableError: PropTypes.string
};

export default TextFieldMessage;
