import React, { useState, useEffect } from "react";
import SmallPrimaryButton from "./SmallPrimaryButton";
import { IoIosArrowDown } from "react-icons/io";
import Logo from "./img/logo.svg";
import "./navbar.css";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [focusedItem, setFocusedItem] = useState("");
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //to change language
  const [t, i18n] = useTranslation();
  const html = document.querySelector("html");
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
    console.log("dir", dir);
    console.log("i18n.language", i18n.language);
  }, [i18n]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("selectedLanguage", lang);
    document.querySelector("html").setAttribute("lang", lang);
  };
  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setShowLanguageMenu(false);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling effect
    });
  };

  // Function to handle focus change
  const handleFocus = (item) => {
    setFocusedItem(item);
    // Store the focused item in localStorage
    localStorage.setItem("focusedItem", item);
    setMenuOpen(false);
  };

  // // Function to handle language menu focus

  // Function to handle language menu focus
  const handleFocus2 = () => {
    setShowLanguageMenu((prevState) => !prevState);
  };

  // Function to handle blur event
  const handleBlur = () => {
    // Delay hiding the language menu by 500 milliseconds
    setTimeout(() => {
      setShowLanguageMenu(false);
    }, 500);
  };

  useEffect(() => {
    // Retrieve the focused item from localStorage or default based on the current page's path
    const path = location.pathname;
    switch (path) {
      case "/contactus":
        setFocusedItem("تواصل معنا");
        break;
      case "/donation":
        setFocusedItem("تبرع");
        break;
      case "/experts":
        setFocusedItem("الخبراء");
        break;
      case "/workspace":
        setFocusedItem("مساحة العمل");
        break;
      case "/entrepreneurship":
        setFocusedItem("برنامج ريادة الأعمال");
        break;
      case "/corepro":
        setFocusedItem("برنامج التأهيل المهني");
        break;
      case "/aboutus":
        setFocusedItem("من نحن");
        break;
      default:
        setFocusedItem("الرئيسية");
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuOpen]);

  useEffect(() => {
    // Clear the focused item from localStorage when the component unmounts
    return () => {
      localStorage.removeItem("focusedItem");
    };
  }, []);

  return (
    <>
      <div className={`navbarAr ${i18n.language === "en" ? "ltr" : "rtl"}`}>
        <div className="nav-div">
          <ul
            className={menuOpen ? "open" : ""}
            style={{ height: menuOpen ? "100%" : "auto" }}
          >
            {/* logo div */}
            <div className="main-lg">
              <NavLink to={"/"}>
                <img
                  src={Logo}
                  alt="logo"
                  style={{ width: "170px", height: "64px", marginLeft: "30px" }}
                />
              </NavLink>
            </div>
            {/*  home tab */}
            <li>
              <NavLink
                to="/"
                className={`text-wrapper ${
                  focusedItem === "الرئيسية" ? "focused" : ""
                }`}
                onClick={() => {
                  handleClick();
                  handleFocus("الرئيسية");
                }}
                tabIndex="0"
              >
                {t("Home")}
              </NavLink>
            </li>

            {/* aboutus tab */}
            <li>
              <NavLink
                to="/aboutus"
                className={`text-wrapper ${
                  focusedItem === "من نحن" ? "focused" : ""
                }`}
                onClick={() => {
                  handleClick();
                  handleFocus("من نحن");
                }}
                tabIndex="0"
              >
                {t("AboutUs")}
              </NavLink>
            </li>

            {/* corepro tab */}
            <li>
              <NavLink
                to="/corepro"
                className={`text-wrapper ${
                  focusedItem === "برنامج التأهيل المهني" ? "focused" : ""
                }`}
                onClick={() => {
                  handleClick();
                  handleFocus("برنامج التأهيل المهني");
                }}
                tabIndex="0"
              >
                {t("VocationalTrainingProgrammes")}
              </NavLink>
            </li>

            {/* entrepreneurship tab */}
            <li>
              <NavLink
                to="/entrepreneurship"
                className={`text-wrapper ${
                  focusedItem === "برنامج ريادة الأعمال" ? "focused" : ""
                }`}
                onClick={() => {
                  handleClick();
                  handleFocus("برنامج ريادة الأعمال");
                }}
                tabIndex="0"
              >
                {t("EntrepreneurshipProgram")}
              </NavLink>
            </li>

            {/* workspace tab */}
            <li>
              <NavLink
                to="/workspace"
                className={`text-wrapper ${
                  focusedItem === "مساحة العمل" ? "focused" : ""
                }`}
                onClick={() => {
                  handleClick();
                  handleFocus("مساحة العمل");
                }}
                tabIndex="0"
              >
                {t("Space")}
              </NavLink>
            </li>

            {/* experts tab */}
            <li>
              <NavLink
                to="/experts"
                className={`text-wrapper ${
                  focusedItem === "الخبراء" ? "focused" : ""
                }`}
                onClick={() => {
                  handleClick();
                  handleFocus("contactus");
                  setMenuOpen(false);
                }}
                tabIndex="0"
              >
                {t("Experts")}
              </NavLink>
            </li>

            <li>
              {windowWidth > 1180 ? (
                <div
                  id="arabic"
                  className={`text-wrapper2 ${
                    focusedItem === "العربية" ? "focused" : ""
                  }`}
                  onClick={() => handleFocus2("arabic")}
                  onBlur={handleBlur}
                  tabIndex="0"
                >
                  <span style={{ marginRight: "20px", marginTop: "-30px" }}>
                    {i18n.language === "ar" ? "العربية" : "English"}
                  </span>
                  <IoIosArrowDown
                    className="arrowDown"
                    style={{
                      fontSize: "1.1em",
                      marginTop: "-28px",
                      marginRight: "8px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <div
                  id="arabicMobile"
                  className={`text-wrapper2mobile ${
                    focusedItem === "العربية" ? "focused" : ""
                  }`}
                  onClick={() => handleFocus2("العربية")}
                  onBlur={handleBlur}
                  tabIndex="0"
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                      marginLeft: "10px",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <IoIosArrowDown
                      className="arrowDown"
                      style={{
                        fontSize: "1.1em",
                        marginTop: "-28px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        marginTop: "-30px",
                        cursor: "pointer",
                      }}
                    >
                      {i18n.language === "ar" ? "العربية" : "English"}
                    </span>
                  </div>
                </div>
              )}

              {showLanguageMenu && (
                <div className="dropdown-menu">
                  <LanguageButton
                    language="العربية"
                    onClick={() => handleLanguageChange("ar")}
                  />
                  <LanguageButton
                    language="English"
                    onClick={() => handleLanguageChange("en")}
                  />
                </div>
              )}
            </li>

            {/* contact us */}
            <li>
              <NavLink
                onClick={() => {
                  handleClick();
                  handleFocus("تواصل معنا");
                }}
                to="/contactus"
              >
                <SmallPrimaryButton
                  className="small-primary-button"
                  width={122}
                  height={42}
                  text={t("ContactUs")}
                  colorText="white"
                />
              </NavLink>
            </li>
          </ul>

          <div className="menu-logo">
            {/* Render the logo image only when the menu is closed */}
            {!menuOpen && (
              <NavLink to={"/"}>
                <img src={Logo} alt="Logo" className="logo-small" />
              </NavLink>
            )}

            <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? (
                <>
                  <span className="line1"></span>
                  <span className="line2"></span>
                </>
              ) : (
                <>
                  <span></span>
                  <span></span>
                  <span></span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

export const LanguageButton = ({ language, onClick }) => {
  return (
    <div className="language-button" onClick={onClick}>
      <div className="text-wrapper2">{language}</div>
    </div>
  );
};
