import MeduimPrimaryButton from "./MediumPrimaryButton";
import "../components/contactUsForm.css";
import vector from "./img/Line 4.svg";
import TextInput from "./TextInput";
import TextFieldMessage from "./TextFieldMessage";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const ContactUsForm = ({ divClassName }) => {
  const { t } = useTranslation();

  const [buttonWidth, setButtonWidth] = useState(752); // Default width for the button

  useEffect(() => {
    const handleResize = () => {
      // Update button width based on screen size
      if (window.innerWidth <= 425) {
        setButtonWidth("320px");
      } else if (window.innerWidth <= 1180) {
        setButtonWidth("390px");
      } else {
        setButtonWidth("752px"); // Default width
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize to set initial width
    handleResize();

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="contact-us-form">
      <div className="div-contact">
        <div className="frame-2">
          <h4 className={`text-wrapper-3 ${divClassName}`}>
            {t("happytohelp")}
          </h4>
          <p className="p-contact">{t("happytohelpText")}</p>
        </div>
        {/* <img src={vector} alt="" /> */}
        <div className="frame-3">
          <div className="frame-4">
            <TextInput
              className="secondary-text-input-required"
              placeholder={t("formPhone")}
              size="small"
              disableTitle={false}
              disableErrorText={false}
            />
            <TextInput
              className="secondary-text-input-required"
              placeholder={t("formName")}
              size="small"
              disableTitle={false}
              disableErrorText={false}
            />
          </div>
          <TextInput
            className="secondary-text-input-instance"
            placeholder={t("conEmail")}
            size="medium"
            disableTitle={false}
            disableErrorText={false}
          />
          <TextInput
            className="secondary-text-input-instance"
            placeholder={t("subject")}
            size="medium"
            disableTitle={false}
            disableErrorText={false}
          />
          <TextFieldMessage
            disableError={true}
            className="textfield-message-required"
            inputClassName="textfield-message-instance"
            inputPlaceholder={t("howToHelp")}
            size="long"
          />
        </div>
        <MeduimPrimaryButton
          className="meduim-primary-button"
          text={t("send")}
          width={buttonWidth}
        />
      </div>
    </div>
  );
};
