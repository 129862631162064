import { useMediaQuery } from "react-responsive";
import React, { useState, useEffect } from "react";
import "./incubationProgram.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const IncubationProgram = ({ className }) => {
  const { t } = useTranslation();
  const html = document.querySelector("html");

  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 1180 });

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`incubation-program ${className}`}>
      <div
        className={`div-incubation ${
          i18n.language === "en" ? "english" : " arabic"
        }`}
      >
        <dgniv
          className={`frame-33 ${
            scrollPosition > (isMobile ? 3300 : 2000) &&
            scrollPosition <= (isMobile ? 3700 : 2800)
              ? "visible"
              : ""
          }`}
        >
          <div className="text-wrapper-26">01</div>
          <div className="frame-34">
            <div className="text-wrapper-27"> {t("ApplicationStage")} </div>
            <p className="text-wrapper-28">{t("ApplicationStageText")}</p>
          </div>
        </dgniv>

        <div
          className={`frame-35 ${
            scrollPosition > (isMobile ? 3700 : 2800) &&
            scrollPosition <= (isMobile ? 4000 : 3200)
              ? "visible"
              : ""
          }`}
        >
          <div className="text-wrapper-26">02</div>
          <div className="frame-34">
            <div className="text-wrapper-27"> {t("TrainingCamp")}</div>
            <p className="text-wrapper-28">{t("TrainingCampText")}</p>
          </div>
        </div>
        <div
          className={`frame-36 ${
            scrollPosition > (isMobile ? 4000 : 3200) &&
            scrollPosition <= (isMobile ? 4400 : 3600)
              ? "visible"
              : ""
          }`}
        >
          <div className="text-wrapper-26">03</div>
          <div className="frame-34">
            <div className="text-wrapper-27">{t("Incubation")}</div>
            <p className="text-wrapper-28">{t("IncubationText")}</p>
          </div>
        </div>
        <div
          className={`frame-384 ${
            scrollPosition > (isMobile ? 4400 : 3600) &&
            scrollPosition <= (isMobile ? 4850 : 4000)
              ? "visible"
              : ""
          }`}
        >
          <div className="text-wrapper-26">04</div>
          <div className="frame-34">
            <div className="text-wrapper-27">{t("FinalJudgingDay")} </div>
            <p className="text-wrapper-28">{t("FinalJudgingDayText")}</p>
          </div>
        </div>
        <div
          className={`frame-37 ${
            scrollPosition > (isMobile ? 4850 : 4000) &&
            scrollPosition <= (isMobile ? 5250 : 4500)
              ? "visible"
              : ""
          }`}
        >
          <div className="text-wrapper-26">05</div>
          <div className="frame-34">
            <div className="text-wrapper-27">{t("PostIncubation")} </div>
            <p className="text-wrapper-28">{t("PostIncubationText")}</p>
          </div>
        </div>
        <hr class="vertical-line" />
        <hr class="vertical-line2" />
        <hr class="vertical-line3" />
        <hr class="vertical-line4" />
      </div>
    </div>
  );
};

export default IncubationProgram;
