import React from "react";
import './info.css';

const Tag = ({ text }) => {
  return (
    <div
      className='tag'
      // style={{ width: "160px", height: "40px" }}
    >
      <div className="text-tag">{text}</div>
    </div>
  );
};

export default Tag;
