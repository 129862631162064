import PropTypes from "prop-types";
import "./variableLocation.css";

export const VariableLocation = ({ property1 }) => {
  return (
    <div className="location">
      <div className={`div-location ${property1}`}>
        <p className="researches">
          <span className="text-wrapper">Yenibosna Metro </span>
          <span className="span-location">— 79F, 36AY, 79K</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kuyumcukent</span>
          <span className="span-location">— 98B, 31, HT11</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kuyumcukent Çarşı </span>
          <span className="span-location">— 31E, 82</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Bakırköy </span>
          <span className="span-location"> — 98T</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Başakşehir Metrokent </span>
          <span className="span-location">— 98H</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Zeytinburnu </span>
          <span className="span-location">— 79G</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kanuni Sultan Süleyman </span>
          <span className="span-location">— 98T, 89YB</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Esenkent </span>
          <span className="span-location">— E-57</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Marmara Sanayi Sitesi </span>
          <span className="span-location">— 89M, 98MB</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Fenertepe </span>
          <span className="span-location">— 79FY</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kuleli </span>
          <span className="span-location">— AVR1</span>
        </p>
      </div>
      <div className={`div-location ${property1}`}>
        <p className="researches">
          <span className="text-wrapper">Yenibosna Metro </span>
          <span className="span-location">— 79F, 36AY, 79K</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kuyumcukent</span>
          <span className="span-location">— 98B, 31, HT11</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kuyumcukent Çarşı </span>
          <span className="span-location">— 31E, 82</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Bakırköy </span>
          <span className="span-location"> — 98T</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Başakşehir Metrokent </span>
          <span className="span-location">— 98H</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Zeytinburnu </span>
          <span className="span-location">— 79G</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kanuni Sultan Süleyman </span>
          <span className="span-location">— 98T, 89YB</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Esenkent </span>
          <span className="span-location">— E-57</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Marmara Sanayi Sitesi </span>
          <span className="span-location">— 89M, 98MB</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Fenertepe </span>
          <span className="span-location">— 79FY</span>
        </p>
        <div className="researches-2">-</div>
        <p className="researches">
          <span className="text-wrapper">Kuleli </span>
          <span className="span-location">— AVR1</span>
        </p>
      </div>
    </div>
  );
};

VariableLocation.prototype = {
  property1: PropTypes.oneOf(["variant-2", "default"])
}