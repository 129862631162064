import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import '../components/buttons.css';

export const MeduimPrimaryButton = ({ property1, className, text, height, width }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`meduim-primary ${state.property1} ${className}`}
      style={{width:width, height:height}}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className="text-wrapper">{text}</div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "hover",
        };
        default:
        return state;
    }
  }

  if (state.property1 === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "default",
        };

      case "click":
        return {
          property1: "focused",
        };
        default:
        return state;
    }
  }

  return state;
}

MeduimPrimaryButton.propTypes = {
  property1: PropTypes.oneOf(["disabled", "focused", "hover", "default"]),
  text: PropTypes.string,
  size: PropTypes.string
};

export default MeduimPrimaryButton;