import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LatestNewsCard } from "./LatestNewsCard"; // Assuming you have this component
import i18n from "../i18n";
import Rectangle12 from "./img/Rectangle12.jpg";
import { useQuery, gql } from "@apollo/client";

// GraphQL query to fetch the latest 4 news items
const GET_LATEST_NEWS = gql`
  query getLatestNews($locale: I18NLocaleCode) {
    news(
      pagination: { page: 1, pageSize: 4 }
      sort: "createdAt:desc"
      locale: $locale
    ) {
      data {
        id
        attributes {
          title
          subtitle
          publishedAt
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function LatestNewsSection({ className, size }) {
  const locale = i18n.language === "ar" ? "ar" : "en";
  const [latestNewsData, setLatestNewsData] = useState([]);

  // Fetch data using Apollo Client's useQuery
  const { data, loading, error } = useQuery(GET_LATEST_NEWS, {
    variables: { locale },
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setLatestNewsData(data.news.data); // Access the array of latest news items
    }
  }, [data, loading, error]);

  return (
    <div className={className}>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading latest news: {error.message}</p>}
      {!loading &&
        !error &&
        latestNewsData &&
        latestNewsData.length > 0 &&
        latestNewsData.map((newsItem) => {
          const imageData = newsItem.attributes?.image?.data?.attributes;
          const imageUrl = imageData ? imageData.url : Rectangle12; // Fallback if no image
          const publishedDate = newsItem.attributes?.publishedAt;
          const formattedDate = publishedDate.split("T")[0];
          return (
            <Link
              key={newsItem.id}
              onClick={() => console.log(`News card ${newsItem.id} clicked`)}
              to={`/NewsDetails/${newsItem.id}`} // Ensure this path matches your route setup
            >
              <LatestNewsCard
                publishedData={formattedDate}
                size={size}
                title={newsItem.attributes.title}
                text={newsItem.attributes.subtitle}
                imageSrc={imageUrl}
                fontSizeLabel={12}
                disableLabel={true}
              />
            </Link>
          );
        })}
    </div>
  );
}

export default LatestNewsSection;
