import React, { useState } from "react";
import { LiaLinkedin } from "react-icons/lia";
import "../components/cards.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const OurTeamCard = ({ className, image, name, department, href }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { t } = useTranslation();
  const html = document.querySelector("html");

  return (
    <div className={`container-our-team ${className}`}>
      <img
        src={image}
        alt="Team Member"
        className={`image-our-team ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <div className={`overlay ${i18n.language === "ar" ? "ar" : "en"}`}>
        <a href={href} style={{ color: "unset" }}>
          <div className="text">{name}</div>
          <div className="subtitle">{department}</div>
          {/* <div className="row">
            <LiaLinkedin className="linkedin" />
          </div> */}
        </a>
      </div>
    </div>
  );
};

export default OurTeamCard;
