import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Faq from "../components/FAQ";
import Footer from "../components/Footer";
import LargePrimaryButton from "../components/LargePrimaryButton";
import Navbar from "../components/Navbar";
import { FrameHover3 } from "../components/FrameHover3";
import Arrow from "../components/img/arrow-faq.svg";
import Camera from "../components/img/Camera.webp";
import Leaderboard from "../components/img/Leaderboard.webp";
import HTML from "../components/img/HTML.webp";
import Visual_Design from "../components/img/Visual_Design.webp";
import Group from "../components/img/Group.webp";
import Video from "../components/img/Video.webp";
import Pc_Play_Media from "../components/img/Pc_Play_Media.png";
import Shopping_Cart from "../components/img/Shopping_Cart.png";
import financial_database from "../components/img/financial database.webp";
import Vector from "../components/img/Vector.webp";
import Image from "../components/img/coreproImage.webp";
// import Play from "../components/img/video-img.svg";
import logo from "../components/img/logo.png";
import Svg from "../components/img/pngimg 1.png";
import "./corePro.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const CorePro = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 1180 });

  const { t } = useTranslation();
  const html = document.querySelector("html");

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="corepro">
      <div className="div-corepro">
        <div className="overlap">
          <div className="frame-29">
            <div className="frame-30">
              <p className="div-3">
                <span className="span">
                  {t("ReplacedText")}
                  <br />
                </span>
                <span className="text-wrapper-26">{t("coreProSmallText")}</span>
              </p>
              <img className="img-logo" alt="Frame" src={logo} />
            </div>
            <div className="frame-32">
              <p className="div-4">
                <span className="text-wrapper-27">
                  {t("EmpoweringYouthText1")}
                  <br />
                </span>
                <span className="text-wrapper-28">
                  {" "}
                  {t("EmpoweringYouthText")}
                </span>
              </p>
              <div className="frame-33">
                <img className="rectangle" alt="Rectangle" src={Image} />
                <div className="text-wrapper-29">{t("EmpoweringYouth")} </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-34">
          <div className="div-35">
            <div className="frame-35">
              <iframe
                className="rectangle-2"
                src="https://www.youtube.com/embed/Dgm414LAHvs?si=0gJr0xCV4j0t7-_U"
                title="YouTube video player"
                allowfullscreen
              ></iframe>
              {/* <img className="frame-36" alt="Frame" src={Play} /> */}
            </div>
            <div className="frame-37">
              <div className="text-wrapper-30">{t("Card1big")}</div>
              <p className="text-wrapper-31">{t("Card1small")}</p>
            </div>
          </div>
        </div>
        <div className="frame-38">
          <div className="div-35">
            <div className="frame-35">
              <iframe
                className="rectangle-2"
                src="https://www.youtube.com/embed/Dgm414LAHvs?si=0gJr0xCV4j0t7-_U"
                title="YouTube video player"
                allowfullscreen
              ></iframe>
              {/* <img className="frame-36" alt="Frame" src={Play} /> */}
            </div>
            <div className="frame-39">
              <div className="text-wrapper-30"> {t("SuccessStory")}</div>
              <p className="text-wrapper-33">{t("SuccessStoryText")}</p>
            </div>
          </div>
        </div>
        <div className="group-2">
          <div
            className={`frame-40 ${
              scrollPosition > (isMobile ? 3800 : 3500) &&
              scrollPosition <= (isMobile ? 4100 : 3900)
                ? "visible"
                : ""
            }`}
          >
            <div className="text-wrapper-34">01</div>
            <div className="frame-41">
              <p className="text-wrapper-35"> {t("stage1title")} </p>
              <p className="text-wrapper-36">
                {t("stage1step1")}
                <br />
                {t("stage1step2")}
              </p>
            </div>
          </div>
          <div
            className={`frame-42 ${
              scrollPosition > (isMobile ? 4100 : 3900) &&
              scrollPosition <= (isMobile ? 4500 : 4300)
                ? "visible"
                : ""
            }`}
          >
            <div className="text-wrapper-34">02</div>
            <div className="frame-41">
              <div className="text-wrapper-35">{t("stage2title")}</div>
              <p className="text-wrapper-36">
                {t("stage2step1")} <br />
                {t("stage2step2")} <br />
                {t("stage2step3")} <br />
                {t("stage2step4")}
              </p>
            </div>
          </div>
          <div
            className={`frame-43 ${
              scrollPosition > (isMobile ? 4500 : 4300) &&
              scrollPosition <= (isMobile ? 4950 : 4700)
                ? "visible"
                : ""
            }`}
          >
            <div className="text-wrapper-34">03</div>
            <div className="frame-41">
              <div className="text-wrapper-35">{t("stage3title")}</div>
              <p className="text-wrapper-36">
                {t("stage3step1")} <br />
                {t("stage3step2")} <br />
                {t("stage3step3")} <br />
                {t("stage3step4")}
              </p>
            </div>
          </div>
          <div
            className={`frame-44 ${
              scrollPosition > (isMobile ? 4950 : 4700) &&
              scrollPosition <= (isMobile ? 5300 : 5200)
                ? "visible"
                : ""
            }`}
          >
            <div className="text-wrapper-34">04</div>
            <div className="frame-41">
              <div className="text-wrapper-35">{t("stage4title")}</div>
              <p className="text-wrapper-36">
                {t("stage4step1")} <br />
                {t("stage4step2")} <br />
              </p>
            </div>
          </div>
          <hr class="vertical-line" />
          <hr class="vertical-line2" />
          <hr class="vertical-line3" />
        </div>
        <div className="frame-45">
          <div className="div-50">
            <div className="frame-39">
              <div className="text-wrapper-40">{t("CoreProSteps")} </div>
              <p className="text-wrapper-41">{t("ReplacedText")}</p>
            </div>
          </div>
        </div>
        <div className="frame-46">
          <div className="div-50">
            <div className="frame-39">
              <div className="text-wrapper-40">{t("CoreProSteps")} </div>
              <p className="text-wrapper-41">{t("ReplacedText")}</p>
            </div>
          </div>
          <div className="frame-47">
            <Faq
              className="FAQ-instance"
              property1="variant-4"
              title0={t("Q1")}
              text0={t("Ans1")}
              title1={t("Q2")}
              text1={t("Ans2")}
              title2={t("Q3")}
              text2={t("Ans3")}
              title3={t("Q4")}
              text3={t("Ans4")}
              title4={t("Q5")}
              text4={t("Ans5")}
              title5={t("Q6")}
              text5={t("Ans6")}
            />
            <div className="frame-48">
              <div className="frame-49">
                <img src={Arrow} alt="Arrow" />
                <p className="text-wrapper-42">{t("MoreQ")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-50">
          <div className="div-50">
            <div className="frame-39">
              <div className="text-wrapper-40">{t("Training")} </div>
              <p className="text-wrapper-41">{t("ReplacedText")}</p>
            </div>
          </div>
          <div className="frame-51">
            <div className="frame-hover-corepro">
              <FrameHover3
                img={Camera}
                text={t("Photography")}
                property1="default"
              />
              <FrameHover3
                img={Pc_Play_Media}
                text={t("VE")}
                property1="default"
              />
              <FrameHover3
                img={Visual_Design}
                text={t("GraphicDesign")}
                property1="default"
              />
              <FrameHover3
                img={Group}
                text={t("Markiting")}
                property1="default"
              />
            </div>
            <div className="frame-hover-corepro">
              <FrameHover3
                img={financial_database}
                text={t("ACC")}
                property1="default"
              />
              <FrameHover3
                img={Vector}
                text={t("Secretarial")}
                property1="default"
              />
              <FrameHover3
                img={HTML}
                text={t("Programming")}
                property1="default"
              />
              <FrameHover3
                img={Video}
                text={t("VideoShooting")}
                property1="default"
              />
            </div>
            <div className="frame-hover-corepro">
              <FrameHover3
                img={Shopping_Cart}
                text={t("ECom")}
                property1="default"
              />
              <FrameHover3
                img={Leaderboard}
                text={t("DataAnalysis")}
                property1="default"
              />
            </div>
          </div>
        </div>
        <div className="frame-52">
          <div className="frame-53">
            <div className="apply-frame">
              <p className="text-wrapper-43">{t("Applytext")}</p>
              <LargePrimaryButton
                className="button-corepro"
                text={t("ApplyNow")}
                width={140}
                height={50}
              />
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="rectangle-3"></div>
                <img className="png-img" alt="Svg" src={Svg} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="footer" />
    </div>
  );
};
