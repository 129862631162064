import PropTypes from "prop-types";
import "./cards.css";

export const ExpertsCard = ({ imageSrc, text, text1, text2 }) => {
  return (
    <div className="experts-card">
      <img
        src={imageSrc}
        alt="Expert Image"
        className="image-expert"
        loading="lazy"
      />
      <div className="frame-expert">
        <div className="text-wrapper">{text}</div>
        <div className="div">{text1}</div>
      </div>
      <p className="element-baker-mckenzie">{text2}</p>
    </div>
  );
};

ExpertsCard.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
};
