import PropTypes from "prop-types";
import { useReducer } from "react";
import "./contactingComponent.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const ContactingComponent = ({ stateProp, className, size }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default-phone",
  });

  const { t } = useTranslation();
  return (
    <div
      className={`contacting-component ${state.state} ${className} ${
        size === "long" ? "long" : ""
      }`}
      onMouseLeave={() => {
        dispatch({ type: "mouse_leave" }); // Dispatching action object
      }}
      onMouseEnter={() => {
        dispatch({ type: "mouse_enter" }); // Dispatching action object
      }}
    >
      <div className="frame-contacting-component">
        {["default-phone", "stroke-phone"].includes(state.state) && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g id="General/Outline/Phone">
              <path
                className="Shape"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.48456 4.28993C4.1984 4.57641 3.93602 4.92781 3.76636 5.23381C3.55328 5.6078 3.39598 6.01792 3.30114 6.44431L3.25179 6.70194C2.47403 10.6479 3.46178 14.1779 6.72244 17.4153C8.86183 19.5394 10.4841 20.4123 12.7054 20.731C13.0654 20.7826 13.4414 20.8214 13.8887 20.8545L14.8085 20.9104C15.3218 20.937 16.177 20.9796 16.469 20.9962C16.8535 21.0181 17.3622 20.9446 18.0459 20.7844L18.1495 20.7541C18.7463 20.5432 19.2913 20.2012 19.7476 19.7444C21.4175 18.0727 21.4175 15.3623 19.7476 13.6906L19.5988 13.5486C17.9846 12.0787 15.5161 12.0653 13.8868 13.5155L13.6937 13.6903L12.6613 14.7238C12.4351 14.9503 12.0788 14.981 11.8173 14.7965C11.2731 14.4128 9.82742 12.9978 9.47451 12.5155C9.28266 12.2534 9.31052 11.8903 9.54011 11.6606L10.4497 10.7506L10.5275 10.6873L10.643 10.5802C10.6638 10.5602 10.685 10.5393 10.7061 10.5183C12.4241 8.79836 12.4241 6.00984 10.7061 4.28993C8.98805 2.57002 6.20259 2.57002 4.48456 4.28993ZM5.88004 5.68694C6.82736 4.73858 8.36327 4.73858 9.31059 5.68694C10.2579 6.6353 10.2579 8.1729 9.31059 9.12126C9.25198 9.17993 9.19093 9.23522 9.1276 9.28704L8.14498 10.2632C7.22656 11.1823 7.11513 12.6345 7.88252 13.6831C8.36278 14.3394 9.96259 15.9052 10.6807 16.4116L10.8192 16.503C11.8499 17.1374 13.1911 16.9874 14.0568 16.1208L15.0112 15.1651L15.0243 15.1584L15.1162 15.0684C16.0169 14.1869 17.4602 14.1947 18.3521 15.0877C19.2513 15.9878 19.2513 17.4472 18.3521 18.3473L18.1987 18.4879C18.0402 18.6207 17.8665 18.7301 17.6814 18.8147L17.5321 18.8748L17.2784 18.9307L17.0105 18.9809C16.809 19.0146 16.663 19.0284 16.5811 19.0237L14.5773 18.9196L14.0464 18.8851L13.5859 18.8464C13.3701 18.8256 13.1735 18.8023 12.9854 18.7753C11.2071 18.5202 9.96435 17.8515 8.11215 16.0125C5.42085 13.3404 4.58666 10.5763 5.13656 7.36266L5.19408 7.05025C5.24621 6.73027 5.34276 6.45456 5.48632 6.20252C5.57373 6.04499 5.72574 5.84142 5.88004 5.68694Z"
                fill={
                  state.state === "stroke-phone"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
            </g>
          </svg>
        )}

        {["default-email", "stroke-email"].includes(state.state) && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              className="Shape"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V15.5151C22 16.8875 20.8875 18 19.5151 18C19.1982 18 18.9665 18.2989 19.0454 18.6057L19.1067 18.8438C19.7486 21.3403 16.836 23.2115 14.8323 21.59L10.6712 18.2227C10.4932 18.0786 10.2711 18 10.0421 18H5C3.34315 18 2 16.6569 2 15V5ZM5 4C4.44772 4 4 4.44772 4 5V15C4 15.5523 4.44772 16 5 16H10.0421C10.7291 16 11.3953 16.2358 11.9293 16.668L16.0904 20.0353C16.5963 20.4447 17.3318 19.9722 17.1697 19.3419L17.1084 19.1038C16.7041 17.5314 17.8916 16 19.5151 16C19.7829 16 20 15.7829 20 15.5151V5C20 4.44772 19.5523 4 19 4H5Z"
              fill={
                state.state === "stroke-email"
                  ? "var(--orangemain-orange)"
                  : "#191A1A"
              }
            />
            <path
              className="Shape"
              d="M6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7C6.44772 8 6 7.55228 6 7Z"
              fill={
                state.state === "stroke-email"
                  ? "var(--orangemain-orange)"
                  : "#191A1A"
              }
            />
            <path
              className="Shape"
              d="M6 11C6 10.4477 6.44772 10 7 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H7C6.44772 12 6 11.5523 6 11Z"
              fill={
                state.state === "stroke-email"
                  ? "var(--orangemain-orange)"
                  : "#191A1A"
              }
            />
          </svg>
        )}

        {["default-hours", "stroke-hours"].includes(state.state) && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g id="Other/Outline/Business">
              <path
                className="Shape"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.11641 4.19941V19.8005H13.2036V4.19941H3.11641ZM15.0036 19.8005H21.4227V8.34253H15.0036V19.8005ZM15.0036 6.54253V4.04941C15.0036 3.13814 14.2648 2.39941 13.3536 2.39941H2.96641C2.05514 2.39941 1.31641 3.13814 1.31641 4.04941V19.9505C1.31641 20.8618 2.05514 21.6005 2.96641 21.6005H21.5727C22.4839 21.6005 23.2227 20.8618 23.2227 19.9505V8.19253C23.2227 7.28126 22.4839 6.54253 21.5727 6.54253H15.0036Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M17.4492 10.7568C17.2421 10.7568 17.0742 10.9247 17.0742 11.1318V12.6318C17.0742 12.8389 17.2421 13.0068 17.4492 13.0068H18.9492C19.1563 13.0068 19.3242 12.8389 19.3242 12.6318V11.1318C19.3242 10.9247 19.1563 10.7568 18.9492 10.7568H17.4492Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M17.4492 15.2314C17.2421 15.2314 17.0742 15.3992 17.0742 15.6064V17.1064C17.0742 17.3135 17.2421 17.4814 17.4492 17.4814H18.9492C19.1563 17.4814 19.3242 17.3135 19.3242 17.1064V15.6064C19.3242 15.3992 19.1563 15.2314 18.9492 15.2314H17.4492Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M5.2467 6.28226C5.03959 6.28226 4.8717 6.45015 4.8717 6.65726V8.15726C4.8717 8.36436 5.03959 8.53226 5.2467 8.53226H6.7467C6.95381 8.53226 7.1217 8.36436 7.1217 8.15726V6.65726C7.1217 6.45015 6.95381 6.28226 6.7467 6.28226H5.2467Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M9.33673 6.28226C9.12962 6.28226 8.96173 6.45015 8.96173 6.65726V8.15726C8.96173 8.36436 9.12962 8.53226 9.33673 8.53226H10.8367C11.0438 8.53226 11.2117 8.36436 11.2117 8.15726V6.65726C11.2117 6.45015 11.0438 6.28226 10.8367 6.28226H9.33673Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M4.8717 11.1318C4.8717 10.9247 5.03959 10.7568 5.2467 10.7568H6.7467C6.95381 10.7568 7.1217 10.9247 7.1217 11.1318V12.6318C7.1217 12.8389 6.95381 13.0068 6.7467 13.0068H5.2467C5.03959 13.0068 4.8717 12.8389 4.8717 12.6318V11.1318Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M5.2467 15.2314C5.03959 15.2314 4.8717 15.3992 4.8717 15.6064V17.1064C4.8717 17.3135 5.03959 17.4814 5.2467 17.4814H6.7467C6.95381 17.4814 7.1217 17.3135 7.1217 17.1064V15.6064C7.1217 15.3992 6.95381 15.2314 6.7467 15.2314H5.2467Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M8.96173 11.1318C8.96173 10.9247 9.12962 10.7568 9.33673 10.7568H10.8367C11.0438 10.7568 11.2117 10.9247 11.2117 11.1318V12.6318C11.2117 12.8389 11.0438 13.0068 10.8367 13.0068H9.33673C9.12962 13.0068 8.96173 12.8389 8.96173 12.6318V11.1318Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
              <path
                className="Shape"
                d="M9.33673 15.2314C9.12962 15.2314 8.96173 15.3992 8.96173 15.6064V17.1064C8.96173 17.3135 9.12962 17.4814 9.33673 17.4814H10.8367C11.0438 17.4814 11.2117 17.3135 11.2117 17.1064V15.6064C11.2117 15.3992 11.0438 15.2314 10.8367 15.2314H9.33673Z"
                fill={
                  state.state === "stroke-hours"
                    ? "var(--orangemain-orange)"
                    : "#191A1A"
                }
              />
            </g>
          </svg>
        )}
      </div>
      <div className="div">
        <div className="frame-2">
          <div className="div-2">
            {["default-phone", "stroke-phone"].includes(state.state) && (
              <>{t("phone")}</>
            )}

            {["default-email", "stroke-email"].includes(state.state) && (
              <> {t("conEmail")}</>
            )}

            {["default-hours", "stroke-hours"].includes(state.state) && (
              <> {t("workTime")}</>
            )}
          </div>
          <div className="element">
            {["default-phone", "stroke-phone"].includes(state.state) && (
              <span>{t("phoneNum")}</span>
            )}

            {["default-email", "stroke-email"].includes(state.state) && (
              <>info@corebhub.com</>
            )}

            {["default-hours", "stroke-hours"].includes(state.state) && (
              <>{t("workTimeText")} </>
            )}
          </div>
        </div>
        <div className="div-3">
          {["default-phone", "stroke-phone"].includes(state.state) && (
            <> {t("callus")}</>
          )}

          {["default-email", "stroke-email"].includes(state.state) && (
            <> {t("ContactUs")}</>
          )}

          {["default-hours", "stroke-hours"].includes(state.state) && (
            <>10AM - 6PM</>
          )}
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.state === "default-phone") {
    switch (
      action.type // accessing action.type
    ) {
      case "mouse_enter":
        return {
          state: "stroke-phone",
        };
      default:
        return state;
    }
  }

  if (state.state === "stroke-phone") {
    switch (
      action.type // accessing action.type
    ) {
      case "mouse_leave":
        return {
          state: "default-phone",
        };
      default:
        return state;
    }
  }

  if (state.state === "default-email") {
    switch (
      action.type // accessing action.type
    ) {
      case "mouse_enter":
        return {
          state: "stroke-email",
        };
      default:
        return state;
    }
  }

  if (state.state === "stroke-email") {
    switch (
      action.type // accessing action.type
    ) {
      case "mouse_leave":
        return {
          state: "default-email",
        };
      default:
        return state;
    }
  }

  if (state.state === "default-hours") {
    switch (
      action.type // accessing action.type
    ) {
      case "mouse_enter":
        return {
          state: "stroke-hours",
        };
      default:
        return state;
    }
  }

  if (state.state === "stroke-hours") {
    switch (
      action.type // accessing action.type
    ) {
      case "mouse_leave":
        return {
          state: "default-hours",
        };
      default:
        return state;
    }
  }

  return state;
}

ContactingComponent.propTypes = {
  stateProp: PropTypes.oneOf([
    "default-phone",
    "default-hours",
    "default-email",
    "stroke-phone",
    "stroke-hours",
    "stroke-email",
  ]),
};
