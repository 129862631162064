import { PropTypes } from "prop-types";
import MeduimPrimaryButton from "./MediumPrimaryButton";
import TextInput from "./TextInput";
import Rectangle12 from "./img/Rectangle 12.svg";
import Line from "./img/Line 4.svg";
import "./emailNewsletter.css";
import { useTranslation } from "react-i18next";

export const EmailNewsletter = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={`email-newsletter ${className}`}>
      <img src={Rectangle12} alt="Rectangle" className="rectangle" />
      <div className="frame-1">
        <div className="group-2">
          <div className="text-wrapper-email-newsletter">
            {" "}
            {t("NewsLetterTitle")}
          </div>
        </div>
        <p className="p-email-newsletter">{t("NewsLetterText")}</p>
      </div>
      <hr className="line" />
      <div className="frame-2">
        <MeduimPrimaryButton
          className="medium-primary-button"
          text={t("send")}
        />
        <TextInput
          size={"email-news"}
          placeholder="ahmed@gmail.com"
          disableErrorText={false}
          disableTitle={true}
          title={t("email")}
        />
        <TextInput
          size={"email-news"}
          placeholder={t("namePlaceholder")}
          disableErrorText={false}
          disableTitleRequired={false}
          disableTitle={true}
          title={t("name")}
        />
      </div>
    </div>
  );
};
