import React, { useState, useEffect } from "react";
import image1 from "./img/f1.svg";
import image2 from "./img/f2.svg";
import image3 from "./img/f3.svg";
import image4 from "./img/f4.svg";

const LogoSlider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 4); // Hardcoded length since we have 4 images
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const images = [image1, image2, image3, image4];

  return (
    <div className="logo-slider">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index + 1}`}
          style={{
            width: "300px",
            height: "300px",
            display: currentImageIndex === index ? "block" : "none",
          }}
        />
      ))}
    </div>
  );
};

export default LogoSlider;
