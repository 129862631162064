import { useRef, useEffect, useState } from "react";
// import { Footer } from "./Footer";
// import { IconComponentNode } from "./IconComponentNode";
import { ExpertsCard } from "../components/ExpertsCard";
import { EmailTextInput } from "../components/EmailTextInput";
import Expert from "../components/img/expert.webp";
import Expert1 from "../components/img/رشا.webp";
import Expert2 from "../components/img/احمد نجيب.webp";
import Expert3 from "../components/img/فادي.webp";
import Expert4 from "../components/img/محمد عابد.webp";
import Expert5 from "../components/img/مسوتي.webp";
import Expert6 from "../components/img/مصطفى.webp";
import Expert7 from "../components/img/مهند.webp";
import Expert8 from "../components/img/محمد اليف.webp";
import Expert9 from "../components/img/رشيد.webp";
import Expert10 from "../components/img/مدحت.webp";
import Expert11 from "../components/img/صيدم.webp";
import Expert12 from "../components/img/حسام.webp";
import Expert13 from "../components/img/نورالدين.webp";
import "./expert.css";
import Footer from "../components/Footer";
import { SlideArrowLeft } from "../components/SlideArrowLeft";
import { SlideArrowRight } from "../components/SlideArrowRight";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const usePreloadImages = (imageUrls) => {
  useEffect(() => {
    const preloadImage = (url) => {
      const img = new Image();
      img.src = url;
    };

    imageUrls.forEach((url) => {
      preloadImage(url);
    });
  }, [imageUrls]);
};

export const Experts = () => {
  const imageUrls = [
    Expert,
    Expert1,
    Expert2,
    Expert3,
    Expert4,
    Expert5,
    Expert6,
    Expert7,
    Expert8,
    Expert9,
    Expert10,
    Expert11,
    Expert12,
    Expert13,
  ];

  usePreloadImages(imageUrls);

  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [isReversed, setIsReversed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsReversed(window.innerWidth <= 1180);
    };

    // Set initial reversal based on window width
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollRight = () => {
    containerRef.current.scrollBy({
      left: -382, // Adjust this value based on your card width
      behavior: "smooth",
    });
  };

  const handleScrollLeft = () => {
    containerRef.current.scrollBy({
      left: 382, // Adjust this value based on your card width
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Set initial scroll position to the maximum
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, []);

  // to change the direction of the text based on the language
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, [i18n, i18n.language]);

  return (
    <div className="experts">
      <div className="frame-17">
        <div className="frame-18">
          <div className="text-wrapper-14">{t("Experts")}</div>
          <p className="text-wrapper-15">{t("ExpertsText")}</p>
        </div>
        <img className="dsc" alt="Dsc" src={Expert} />
      </div>

      <div className="frame-23">
        <div className="text-wrapper-18"> {t("MeetExperts")} </div>
        <div className="frame-24">
          {i18n.language === "ar" ? (
            <>
              <SlideArrowLeft
                onClick={handleScrollLeft}
                className="design-component-instance-node-2"
                property1="default"
              />
              <SlideArrowRight
                onClick={handleScrollRight}
                className="slide-arrow-right-instance"
                property1="default"
              />
            </>
          ) : (
            <>
              <SlideArrowRight
                onClick={handleScrollRight}
                className="slide-arrow-right-instance"
                property1="default"
              />
              <SlideArrowLeft
                onClick={handleScrollLeft}
                className="design-component-instance-node-2"
                property1="default"
              />
            </>
          )}
        </div>
      </div>

      <div className="frame-19">
        <div className="div-20" ref={containerRef}>
          {isReversed ? (
            <>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert13}
                  text={t("MrNourEddineSaleh")}
                  text1={t("MrNourEddineSalehTitle")}
                  text2={t("MrNourEddineSalehDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert10}
                  text={t("MrMedhatArabi")}
                  text1={t("MrMedhatArabiTitle")}
                  text2={t("MrMedhatArabiDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert11}
                  text={t("MrMohammedSaidam")}
                  text1={t("MrMohammedSaidamTitle")}
                  text2={t("MrMohammedSaidamDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert12}
                  text={t("MrHossamAlKurd")}
                  text1={t("MrHossamAlKurdTitle")}
                  text2={t("MrHossamAlKurdDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert7}
                  text={t("MrMohannadManjad")}
                  text1={t("MrMohannadManjadTitle")}
                  text2={t("MrMohannadManjadDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert8}
                  text={t("MrMohammedElif")}
                  text1={t("MrMohammedElifTitle")}
                  text2={t("MrMohammedElifDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert9}
                  text={t("MrRachidHamwi")}
                  text1={t("MrRachidHamwiTitle")}
                  text2={t("MrRachidHamwiDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert4}
                  text={t("MrMohammedAlAbed")}
                  text1={t("MrMohammedAlAbedTitle")}
                  text2={t("MrMohammedAlAbedDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert5}
                  text={t("MrAbdelRahmanMasouti")}
                  text1={t("MrAbdelRahmanMasoutiTitle")}
                  text2={t("MrAbdelRahmanMasoutiDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert6}
                  text={t("MrMustafaJamall")}
                  text1={t("MrMustafaJamallTitle")}
                  text2={t("MrMustafaJamallDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert1}
                  text={t("MsRashaFakhri")}
                  text1={t("MsRashaFakhriTitle")}
                  text2={t("MsRashaFakhriDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert2}
                  text={t("MrAhmadNajeebArslan")}
                  text1={t("MrAhmadNajeebArslanTitle")}
                  text2={t("MrAhmadNajeebArslanDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert3}
                  text={t("MrFadiAlIssawi")}
                  text1={t("MrFadiAlIssawiTitle")}
                  text2={t("MrFadiAlIssawiDescription")}
                />
              </div>
            </>
          ) : (
            <>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert1}
                  text={t("MsRashaFakhri")}
                  text1={t("MsRashaFakhriTitle")}
                  text2={t("MsRashaFakhriDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert2}
                  text={t("MrAhmadNajeebArslan")}
                  text1={t("MrAhmadNajeebArslanTitle")}
                  text2={t("MrAhmadNajeebArslanDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert3}
                  text={t("MrFadiAlIssawi")}
                  text1={t("MrFadiAlIssawiTitle")}
                  text2={t("MrFadiAlIssawiDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert4}
                  text={t("MrMohammedAlAbed")}
                  text1={t("MrMohammedAlAbedTitle")}
                  text2={t("MrMohammedAlAbedDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert5}
                  text={t("MrAbdelRahmanMasouti")}
                  text1={t("MrAbdelRahmanMasoutiTitle")}
                  text2={t("MrAbdelRahmanMasoutiDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert6}
                  text={t("MrMustafaJamall")}
                  text1={t("MrMustafaJamallTitle")}
                  text2={t("MrMustafaJamallDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert7}
                  text={t("MrMohannadManjad")}
                  text1={t("MrMohannadManjadTitle")}
                  text2={t("MrMohannadManjadDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert8}
                  text={t("MrMohammedElif")}
                  text1={t("MrMohammedElifTitle")}
                  text2={t("MrMohammedElifDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert9}
                  text={t("MrRachidHamwi")}
                  text1={t("MrRachidHamwiTitle")}
                  text2={t("MrRachidHamwiDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert10}
                  text={t("MrMedhatArabi")}
                  text1={t("MrMedhatArabiTitle")}
                  text2={t("MrMedhatArabiDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert11}
                  text={t("MrMohammedSaidam")}
                  text1={t("MrMohammedSaidamTitle")}
                  text2={t("MrMohammedSaidamDescription")}
                />
                <ExpertsCard
                  imageSrc={Expert12}
                  text={t("MrHossamAlKurd")}
                  text1={t("MrHossamAlKurdTitle")}
                  text2={t("MrHossamAlKurdDescription")}
                />
              </div>
              <div className="frame-20">
                <ExpertsCard
                  imageSrc={Expert13}
                  text={t("MrNourEddineSaleh")}
                  text1={t("MrNourEddineSalehTitle")}
                  text2={t("MrNourEddineSalehDescription")}
                />
              </div>
            </>
          )}
        </div>
        <div className="frame-21">
          <div className="frame-22">
            <div className="text-wrapper-16">{t("you")}</div>
            <p className="text-wrapper-17">{t("youText")}</p>
          </div>
          <EmailTextInput className="email-text-input-instance" />
        </div>
      </div>

      <Footer className="footer" />
    </div>
  );
};
