import PropTypes from "prop-types";
import { useReducer } from "react";
import "./frameHover.css";

export const FrameHover3 = ({ property1, className, img, text }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`frame-hover-3 ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`div ${state.property1}`}>
        <img src={img} alt=""
          className="ecommerce-outline"
          color={state.property1 === "variant-2" ? "white" : "#6F2B90"}
        />
        <div className="text-wrapper">{text}</div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

FrameHover3.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
