import "./frameHover.css";

export const FrameHover = ({ className, divClassName, title, text, disable }) => {
  return (
    <div className={`frame-hover ${className}`}>
      <div className="div">
        <div className={`text-wrapper ${divClassName}`}>{title}</div>
        { disable && <p className="p">{text}</p>}
      </div>
    </div>
  );
};
