import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LatestNewsCard } from "./LatestNewsCard";
import i18n from "../i18n";
import Rectangle12 from "./img/Rectangle12.jpg";
import { useQuery, gql } from "@apollo/client";

const GET_NEWS = gql`
  query getNews($locale: I18NLocaleCode) {
    news(locale: $locale) {
      data {
        id
        attributes {
          title
          subtitle
          publishedAt
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function AllNewsSection({ className, size }) {
  const [allNewsData, setAllNewsData] = useState([]);
  const locale = i18n.language === "ar" ? "ar" : "en"; // Determine the locale
  const { data, loading, error } = useQuery(GET_NEWS, {
    variables: { locale }, // Pass the locale variable
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setAllNewsData(data.news.data);
      console.log("Fetched data:", data.news.data);
    }
  }, [data, loading, error]);

  return (
    <div className={className}>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading all news: {error.message}</p>}
      {!loading &&
        !error &&
        allNewsData &&
        allNewsData.length > 0 &&
        allNewsData.map((newsItem) => {
          const imageData = newsItem.attributes?.image?.data?.attributes;
          const publishedDate = newsItem.attributes?.publishedAt;

          const formattedDate = publishedDate.split("T")[0];
          console.log("Formatted date:", formattedDate);

          const imageUrl = imageData ? imageData.url : Rectangle12;

          return (
            <Link
              key={newsItem.id}
              onClick={() => console.log(`News card ${newsItem.id} clicked`)}
              to={`/NewsDetails/${newsItem.id}`}
            >
              <LatestNewsCard
                publishedData={formattedDate}
                size={size}
                title={newsItem.attributes.title}
                text={newsItem.attributes.subtitle}
                imageSrc={imageUrl}
                fontSizeLabel={12}
                disableLabel={true}
              />
            </Link>
          );
        })}
    </div>
  );
}

export default AllNewsSection;
