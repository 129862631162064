import React, { useState, useRef } from "react";
import "./photoAlbum.css"; // Include your CSS file for styling
import { SlideArrowLeft } from "./SlideArrowLeft";
import { SlideArrowRight } from "./SlideArrowRight";

const PhotoAlbum = ({ photos }) => {
  const [mainPhotoIndex, setMainPhotoIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [translation, setTranslation] = useState(0);
  const mainPhotoRef = useRef(null);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStartX) return;

    const touchMoveX = e.touches[0].clientX;
    const diffX = touchStartX - touchMoveX;
    if (diffX > 0) {
      // Swipe left
      handleScrollRight();
    } else if (diffX < 0) {
      // Swipe right
      handleScrollLeft();
    }
    setTouchStartX(null);
  };

  const handleThumbnailClick = (index) => {
    setMainPhotoIndex(index);
  };

  const handleScrollLeft = () => {
    // If we are at the beginning, loop to the last photo
    if (mainPhotoIndex === 0) {
      setMainPhotoIndex(photos.length - 1);
    } else {
      setMainPhotoIndex(mainPhotoIndex - 1);
    }
  };

  const handleScrollRight = () => {
    // If we are at the end, loop back to the first photo
    if (mainPhotoIndex === photos.length - 1) {
      setMainPhotoIndex(0);
    } else {
      setMainPhotoIndex(mainPhotoIndex + 1);
    }
  };

  return (
    <div className="photo-album-container">
      <div className="main-photo-container">
        <div className="arrow-left">
          <div className="arrow-right">
            <SlideArrowRight onClick={handleScrollLeft} />
            <SlideArrowLeft onClick={handleScrollRight} />
          </div>
        </div>
        <div
          // className="main-photo-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={() => setTranslation(0)}
          style={{ transform: `translateX(${translation}px)` }}
        >
          <img
            src={photos[mainPhotoIndex]}
            alt="Main"
            className="main-photo"
            ref={mainPhotoRef}
            loading="lazy"
          />
        </div>
        {/* <img src={photos[mainPhotoIndex]} alt="Main" className="main-photo" /> */}
        {/* <div className='arrow-left'>
          <SlideArrowLeft onClick={handleScrollRight} />
        </div> */}
      </div>
      <div className="thumbnail-container">
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Thumbnail ${index}`}
            className={`thumbnail ${index === mainPhotoIndex ? "active" : ""}`}
            onClick={() => handleThumbnailClick(index)}
            loading="lazy"
          />
        ))}
      </div>
      <div className="thumbnail-small-circle">
        {photos.map((photo, index) => (
          <div
            key={index}
            alt={`Thumbnail ${index}`}
            className={`thumbnail ${index === mainPhotoIndex ? "active" : ""}`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default PhotoAlbum;
