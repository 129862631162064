import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./workspace.css";
import img2 from "../components/img/المساحة المشتركة.webp";
import LogoSlider from "../components/LogoSlider";
import Tag from "../components/Tag";

import play from "./imgPages/Frame 37505.png";
import { FrameHover } from "../components/FrameHover";
import img25 from "../components/img/Map.webp";
import par5 from "../components/img/W1.webp";
import par6 from "../components/img/W2.webp";
import par1 from "../components/img/W3.webp";
import par2 from "../components/img/W4.webp";
import par3 from "../components/img/W5.webp";
import par4 from "../components/img/W6.webp";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const Workspace = () => {
  const { t } = useTranslation();
  const html = document.querySelector("html");
  return (
    <div className="workspace">
      <div className="frame-16">
        <iframe
          className="img"
          src="https://www.youtube.com/embed/lERvd_C6ySY?si=gnC1Ffn14uuR2f58"
          title="YouTube video player"
          allowfullscreen
        ></iframe>

        {/* <img className="play" alt="img2" src={play} /> */}
      </div>

      <div className="frame-17">
        <p className="element">{t("WorkspacesDescription")}</p>
        <div className="text-wrapper-15">{t("WorkspacesTitle")} </div>
      </div>

      <div className="frame-18">
        <p className="title1"> {t("comfortWorkSps")}</p>
        <p className="element1">{t("WorkspacesEnvironment")}</p>
        <img className="img2" alt="Frame" src={img2} />
      </div>

      <div className="frame-19">
        <div className="text-container">
          <span className="element2">
            {t("WorkspacesInclusivity1")} <br /> {t("WorkspacesInclusivity2")}{" "}
            <br />
          </span>
          <span className="element3"> {t("WorkspacesInclusivity3")} </span>
        </div>
        <div className="logo-wrapper">
          <LogoSlider />
        </div>
      </div>
      <div className="div-title2">
        <p className="title2"> {t("ExploreCoreIstanbulSpace")} </p>
      </div>
      <div className="frame-20">
        <iframe
          className="threeD"
          src="https://app.lapentor.com/sphere/workspace"
          allowfullscreen
        ></iframe>
      </div>

      <div className="frame-screen">
        <p className="text-wrapper-2"> {t("WhyChooseCoreIstanbulSpace")} </p>
        <div className="frame-2">
          <div className="frame-3">
            <FrameHover
              className="frame-37512"
              title={t("CreativeAndIntegratedWorkEnvironment")}
            />
            <FrameHover
              className="frame-37512"
              title={t("NetworkingAndBuildingRelationships")}
            />
          </div>
          <div className="frame-3">
            <FrameHover className="frame-37512" title={t("EasyAccess")} />
            <FrameHover
              className="frame-37512"
              title={t("ParticipateInEvents")}
            />
          </div>
        </div>
      </div>

      <div className="parent">
        <div className="frame-28">
          <div className="frame-29"> {t("SharedWorkspace")}</div>
          <p className="text-wrapper-21">{t("CreativeWorkspace")}</p>
          <div class="buttons-container">
            <Tag text={t("HighSpeedInternet")} colorText="white" />
            <Tag text={t("CreativeSpaces")} colorText="white" />
            <Tag text={t("HotDrinks")} colorText="white" />
            <Tag text={t("LaserPrintingServices")} colorText="white" />
          </div>
        </div>
        <img className="rectangle-2" alt="Rectangle" src={par5} />
      </div>

      <div className="parent1">
        <img className="par1" alt="par1" src={par1} />
        <div className="frame-par1">
          <div className="frame-p29"> {t("MeetingRooms")} </div>
          <p className="text-wrapper-p21">{t("MeetingRoomDescription")}</p>
          <div className="buttons-container-p">
            <Tag text={t("DisplayScreen")} colorText="white" />
            <Tag text={t("LaserPrintingServices")} colorText="white" />
            <Tag text={t("LaserPrintingServices")} colorText="white" />
            <Tag text={t("Whiteboard")} colorText="white" />
          </div>
        </div>
      </div>

      <div className="parent2">
        <div className="frame-28">
          <div className="frame-29">{t("TrainingRooms")} </div>
          <p className="text-wrapper-21">{t("TrainingRoomDescription")}</p>
          <div class="buttons-container">
            <Tag text={t("DisplayScreen")} colorText="white" />
            <Tag text={t("LaserPrintingServices")} colorText="white" />
            <Tag text={t("LaserPrintingServices")} colorText="white" />
            <Tag text={t("Whiteboard")} colorText="white" />
          </div>
        </div>
        <img className="par2" alt="par2" src={par2} />
      </div>

      <div className="parent3">
        <img className="par3" alt="Rectangle" src={par3} />
        <div className="frame-par3">
          <div className="frame-p29"> {t("specialOffice")}</div>
          <p className="text-wrapper-p21">{t("SpecialOfficeDescription")}</p>
          <div className="buttons-container-p-4">
            <Tag text={t("TechnicalSupport")} colorText="white" />

            <Tag text={t("LaserPrintingServices")} colorText="white" />
            <Tag text={t("Whiteboard")} colorText="white" />

            <Tag text={t("LogisticalSupport")} colorText="white" />
            <Tag text={t("HighSpeedInternet")} colorText="white" />
          </div>
        </div>
      </div>

      <div className="parent4">
        <div className="frame-28">
          <div className="frame-29"> {t("BusinessCafe")}</div>
          <p className="text-wrapper-21">{t("CafeDescription")}</p>
        </div>
        <img className="par4" alt="Rectangle" src={par4} />
      </div>
      {/* <img src={img25} className="img25" /> */}
      <Footer className="footer-instance" />
    </div>
  );
};
