import React, { useState, useEffect } from "react";
import i18n from "i18next";
import useFetch from "./hooks/useFetch"; // Assuming you have a custom useFetch hook
import OurTeamCard from "./OurTeamCard"; // Import your team card component
import defaultImage from "./img/defaultTeam.webp"; // Import a default image for team members
import { useQuery, gql } from "@apollo/client";

const GET_TEAM_CARDS = gql`
  query getTeamCards($locale: I18NLocaleCode) {
    teams(locale: $locale, sort: "id:asc") {
      data {
        id
        attributes {
          name
          position
          linkedin
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function TeamCardsContainer() {
  const locale = i18n.language === "ar" ? "ar" : "en";
  const [teamCards, setTeamCards] = useState([]);
  const { data, isLoading, error } = useQuery(GET_TEAM_CARDS, {
    variables: { locale }, // Pass the locale variable to the query
  });
  useEffect(() => {
    if (data && !isLoading && !error) {
      const cards = data.teams.data.map((teamMember) => {
        const imageData = teamMember.attributes.image.data[0].attributes;
        const imageUrl = imageData ? imageData.url : defaultImage;

        return (
          <OurTeamCard
            className="our-team-card"
            key={teamMember.id}
            href={teamMember.attributes.linkedin}
            image={imageUrl}
            name={teamMember.attributes.name}
            department={teamMember.attributes.position}
          />
        );
      });
      setTeamCards(cards);
      console.log("Fetched data:", cards);
    }
  }, [data, isLoading, error, locale]);

  return teamCards;
}

export default TeamCardsContainer;
