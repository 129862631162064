import React from "react";
import { ContactingComponent } from "../components/ContactingComponent";
import { ContactUsForm } from "../components/ContactUsForm";
import { VariableLocation } from "../components/VariableLocation";
import Navbar from "../components/Navbar";
import "./contactUs.css";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className="contact-us">
      <div className="div-5">
        <div className="frame-23">
          <div className="frame-24">
            <div className="text-wrapper-19">{t("ContactUs")} </div>
            <p className="text-wrapper-20">{t("ContactUsText")}</p>
          </div>
          <div className="frame-25">
            <ContactingComponent stateProp="default-phone" />
            <ContactingComponent stateProp="default-hours" />
            <ContactingComponent stateProp="default-email" size="long" />
          </div>
        </div>
        <div className="contact-us-form-instance">
          <ContactUsForm />
        </div>
        <div className="frame-26">
          <div className="text-wrapper-21"> {t("Coffee")}</div>
          <div className="frame-24">
            <p className="div-6">
              <span className="text-wrapper-22">
                {t("coreistanbullocation")}
              </span>
              <span className="text-wrapper-23"> {t("yenibosna")}</span>
              <span className="text-wrapper-22"></span>
              <br />
              <br />
              <iframe
                className="map"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Yenibosna%20Merkez,%2029%20Ekim%20Cd.%20No:%2011C%20D:51,%2034197%20Bah%C3%A7elievler/%C4%B0stanbul+(Core%20Istanbul)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/">gps tracker sport</a>
              </iframe>
            </p>
          </div>
        </div>
        <div className="frame-27">
          <p className="text-wrapper-24">{t("buses")}</p>
          <VariableLocation className="frame-37539" property1="default" />
        </div>
        <Footer className="footer-instance" />
      </div>
    </div>
  );
};
