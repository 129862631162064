// import { Footer } from "./Footer";
// import { IconComponentNode } from "./IconComponentNode";
import { useState, useEffect } from "react";
import MeduimPrimaryButton from "../components/MediumPrimaryButton";
import { LatestNewsCard } from "../components/LatestNewsCard";
import TextInput from "../components/TextInput";
import Square from "../components/img/square.svg";
import NewsCard from "../components/img/small-card.svg";
import Rectangle from "../components/img/small-card.svg";
import "./news.css";
import { EmailNewsletter } from "../components/EmailNewsletter";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import AllNewsSection from "../components/AllNewsSection";
import LatestNewsSection from "../components/LastestNewsSection";
import { useQuery, gql } from "@apollo/client";


export const News = () => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fontSizeView, setFontSizeView] = useState(20); // Default font size
  const [fontSizeDate, setFontSizeDate] = useState(20);
  const [fontSizeLabel, setFontLabel] = useState(14);
  const [disableLabel, setDisableLabel] = useState(false);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling effect
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1180) {
        setFontSizeView(12); // Set smaller font size for screens <= 528px
        setFontSizeDate(12);
        setFontLabel(8);
        setDisableLabel(true);
      } else {
        setFontSizeView(20);
        setFontSizeDate(20);
        setFontLabel(14);
        setDisableLabel(false);
      }
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the correct font size on component mount
    handleResize();

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="news-feed">
      <div className="frame-28">
        <div className="frame-29">
          <div className="text-wrapper-29">{t("CoreLastestNews")}</div>
        </div>
        <div>
          <LatestNewsSection className="frame-30" size="square" />
        </div>
      </div>
      <div className="frame-31">
        <hr className="line" />
        <div className="frame-32">
          <div className="text-wrapper-30">{t("MoreNews")}</div>
          <div className="frame-33">
            <AllNewsSection size="large" />
          </div>
        </div>
      </div>
      <div className="email-div">
        <EmailNewsletter className="email-newsletter" />
      </div>
      <Footer className="footer" />
    </div>
  );
};
