import Vector1 from "./img/vector-6.svg";
import Vector2 from "./img/vector-5.svg";
import "./cards.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const Cardinfo = ({
  fontSizeView,
  fontSizeDate,
  fontSizeLabel,
  disableLabel,
  publishedDate,
}) => {
  const { t } = useTranslation();
  return (
    <div className="frame-card-info">
      <hr className="vector-card-info" />
      <div className="date-card-info">{publishedDate}</div>
      {disableLabel && (
        <>
          <hr className="vector-card-info" />
          <div className="div-card-info">
            <div
              className="label-card-info"
              style={{ fontSize: fontSizeLabel }}
            >
              {t("New")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
