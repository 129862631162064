import React, { useState } from 'react';
import '../components/texts.css';

const PrimaryTextInput = ({size, placeholder, disableTitle, disableTitleRequired, disableErrorText, title}) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [editing, setEditing] = useState(false);

    const handleChange = (event) => {
        setInputValue(event.target.value);
        setError(false);
        setEditing(true);
    };

    const handleBlur = () => {
        setEditing(false);
      };

      const handleValidation = () => {
        if (inputValue.trim() === '') {
          setError(true);
        }
      };

    return (
        <div className={`text-input-primary ${editing ? 'editing' : ''} ${error ? 'error' : ''}`}>
                    <div className="label-primary">
                    {disableTitle && (
                    <p className='primary-input'>
                        <span className='text-wraper-primary'>{title} </span>
                        {disableTitleRequired && (  <span className='required-primary'>*</span> )}
                    </p>
                )}
                    </div>
                    <input
                        type="text"
                        placeholder={placeholder}
                        className={`text-input-field-primary ${size === "email-news" ? "email-news" : ""} ${size === "small" ? "small" : ""} ${size === "medium" ? "medium" : ""}`}
                        value={inputValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyUp={handleValidation}
                    />
                    <div className='valid-label-primary'>
                    {error && disableErrorText && <p className="error-text-primary">النص هنا</p>}
                    </div>
        </div>
     );
}

export default PrimaryTextInput;
