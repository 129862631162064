import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./styles/animations.css";

import Rectangle1 from "../components/img/1.webp";
import Rectangle2 from "../components/img/2.webp";
import Rectangle3 from "../components/img/3.webp";
import Rectangle5 from "../components/img/4.webp";
import Rectangle4 from "../components/img/5.webp";
import Pattern1 from "../components/img/PatternHome1.svg";
import Pattern2 from "../components/img/PatternHome2.svg";
import Pattern3 from "../components/img/PatternHome3.svg";
import Pattern4 from "../components/img/PatternHome4.svg";
import Pattern5 from "../components/img/PatternHome5.svg";
import Pattern6 from "../components/img/PatternHome6.svg";
import Pattern8 from "../components/img/PatternHome8.svg";
import Pattern9 from "../components/img/PatternHome9.svg";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

//preload images function
const usePreloadImages = (images) => {
  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, [images]);
};

export const AnimationComponent = ({ className }) => {
  const { t } = useTranslation();

  const html = document.querySelector("html");
  const [property1, setProperty1] = useState("default");

  const imagesToPreload = [
    Rectangle1,
    Rectangle2,
    Rectangle3,
    Rectangle4,
    Rectangle5,
  ];

  usePreloadImages(imagesToPreload);

  useEffect(() => {
    const interval = setInterval(() => {
      // Define the states in an array
      const states = [
        "default",
        "variant-2",
        "variant-3",
        "variant-4",
        "variant-5",
      ];
      // Get the index of the next state
      const nextIndex = (states.indexOf(property1) + 1) % states.length;
      // Set the next state
      setProperty1(states[nextIndex]);
    }, 5000);
    return () => clearInterval(interval);
  }, [property1]);

  const alignmentStyle = {
    alignItems: i18n.language === "ar" ? "flex-end" : "flex-start",
  };

  return (
    <div className={`animation-component ${className}`}>
      <div className={`overlap-group ${property1} `}>
        <div className="frame">
          {["variant-2", "variant-3", "variant-5"].includes(property1) && (
            <div className="div">
              <div className="div-2">
                {property1 === "variant-3" && (
                  <p className="text-wrapper-animation">{t("Card3big")}</p>
                )}

                {property1 === "variant-2" && (
                  <p className="text-wrapper-animation">{t("Card2big")}</p>
                )}

                {property1 === "variant-5" && (
                  <p className="text-wrapper-animation">
                    <br />
                    {t("Card5big")}
                  </p>
                )}
              </div>
              {["variant-2", "variant-5"].includes(property1) && (
                <div className="div-3" style={alignmentStyle}>
                  {property1 === "variant-2" && (
                    <p className="p-animation">{t("Card2small")}</p>
                  )}

                  {property1 === "variant-5" && <p className="p-animation"></p>}
                </div>
              )}
            </div>
          )}

          <img
            className="dsc"
            alt="Dsc"
            loading="lazy"
            src={
              property1 === "variant-3"
                ? Rectangle3
                : property1 === "variant-5"
                ? Rectangle5
                : property1 === "default"
                ? Rectangle1
                : property1 === "variant-4"
                ? Rectangle4
                : Rectangle2
            }
          />

          {["default", "variant-4"].includes(property1) && (
            <div className="frame-2" style={alignmentStyle}>
              {property1 === "default" && (
                <>
                  <div className="text-wrapper-animation">{t("Card1big")}</div>
                  <p className="div-3">{t("Card1small")}</p>
                </>
              )}

              {property1 === "variant-4" && (
                <p className="text-wrapper-animation">{t("Card4big")}</p>
              )}
            </div>
          )}
        </div>
        <img
          className="layer"
          alt="Layer"
          loading="lazy"
          src={
            property1 === "variant-2"
              ? Pattern6
              : property1 === "variant-3"
              ? Pattern5
              : property1 === "variant-4"
              ? Pattern9
              : property1 === "variant-5"
              ? Pattern2
              : Pattern9
          }
        />
        <img
          className="vector"
          alt="Vector"
          loading="lazy"
          src={
            property1 === "variant-2"
              ? Pattern2
              : property1 === "variant-3"
              ? Pattern4
              : property1 === "variant-4"
              ? Pattern3
              : property1 === "variant-5"
              ? Pattern1
              : Pattern8
          }
        />
      </div>
    </div>
  );
};

AnimationComponent.propTypes = {
  property1: PropTypes.oneOf([
    "default",
    "variant-2",
    "variant-3",
    "variant-4",
    "variant-5",
  ]),
};
