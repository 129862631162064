import { Cardinfo } from "./CardInfo";
import SmallSecondaryButton from "./SmallSecondaryButton";
import "./cards.css";

export const LatestNewsCard = ({
  className,
  size,
  title,
  text,
  imageSrc,
  imgClassName,
  fontSizeView,
  fontSizeLabel,
  fontSizeDate,
  disableLabel,
  disableCardInfo,
  disableStoryButton,
  href,
  publishedData,
}) => {
  return (
    <div
      className={`news-card ${className} ${size === "large" ? "large" : ""} ${
        size === "small" ? "small" : ""
      }
        ${size === "medium" ? "medium" : ""} ${
        size === "square" ? "square" : ""
      }`}
    >
      <div className="frame1-news-card">
        <div className="frame2-news-card">
          <div className="title-news-card">{title}</div>
          <p className="p-news-card">{text}</p>
        </div>
        {!disableCardInfo && (
          <Cardinfo
            publishedDate={publishedData}
            className="card-info-news"
            fontSizeView={fontSizeView}
            fontSizeDate={fontSizeDate}
            fontSizeLabel={fontSizeLabel}
            disableLabel={disableLabel}
          />
        )}
        {disableStoryButton && (
          <a href={href}>
            <SmallSecondaryButton text="اقرأ القصة" width={120} height={35} />
          </a>
        )}
      </div>
      <div>
        <img className="rectangle-news-card" src={imageSrc} alt={title} loading="lazy" />
      </div>
    </div>
  );
};
