import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./labels.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const VariableLabel = () => {
  const [property1, setProperty1] = useState("one"); // Initial state

  const { t } = useTranslation();
  const html = document.querySelector("html");

  // Array containing possible values for property1
  const propertyValues = ["one", "two", "three", "four"];

  // Function to update property1 every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Get the index of the next property value
      const currentIndex = propertyValues.indexOf(property1);
      const nextIndex = (currentIndex + 1) % propertyValues.length;
      // Update property1 with the next value
      setProperty1(propertyValues[nextIndex]);
    }, 2000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [property1]); // Dependency array ensures this effect runs whenever property1 changes

  return (
    <div className={`variable-label ${property1}`}>
      <div className="div-variable-label">
        <span className="span-variable-label">
          {["one", "three"].includes(property1) && <> {t("Zero")} </>}
          {["four", "two"].includes(property1) && <> {t("Zero")}</>}
        </span>

        <span className="span-variable-label2">
          {property1 === "one" && <> {t("One")} </>}
          {property1 === "two" && <>{t("Two")}</>}
          {property1 === "three" && <>{t("Three")}</>}
          {property1 === "four" && <>{t("Four")}</>}
        </span>
        {["one", "two"].includes(property1) && (
          <p className="p-variable-label">
            <span className="span-variable-label3">&nbsp;</span>
          </p>
        )}
      </div>
    </div>
  );
};

VariableLabel.propTypes = {
  property1: PropTypes.oneOf(["one", "two", "three", "four"]),
};
