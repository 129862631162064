import PropTypes from "prop-types";
import { useReducer } from "react";
import ChevronRight from "../components/img/Chevron_Right.svg";
import "./arrows.css"

export const SlideArrowLeft = ({ property1, icon = ChevronRight, onClick }) => {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || "default",
    });
    return (
        <div className={`slide-arrow-left ${state.property1}`}
            onMouseLeave={() => {
                dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
                dispatch("mouse_enter");
            }}
            onClick={onClick}
        >
            <img src={icon} alt="Icon" className="chevron"/>
        </div>
    );
};

function reducer(state, action) {
    switch (action) {
        case "mouse_enter":
            return {
                ...state,
                property1: "hover",
            };
        case "mouse_leave":
            return {
                ...state,
                property1: "default",
            };
            default:
                return state;
    }
}

SlideArrowLeft.prototype = {
    property1: PropTypes.oneOf(["hover", "default"])
}