import PropTypes from "prop-types";
import React from "react";
import ArrowLeft from "./img/arrow-left.svg";
import "./texts.css";
import { useTranslation } from "react-i18next";

export const EmailTextInput = ({ className, arrowLeft = ArrowLeft }) => {
  const { t } = useTranslation();
  return (
    <div className={`email-text-input ${className}`}>
      <img className="arrow-left" alt="Arrow left" src={arrowLeft} />
      <input
        type="email"
        name="email"
        placeholder={t("yourEmail")}
        className="text-email"
      />
    </div>
  );
};

EmailTextInput.propTypes = {
  arrowLeft: PropTypes.string,
};
