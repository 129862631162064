import React from "react";
import { HiOutlineMapPin } from "react-icons/hi2";
import { AiOutlineYoutube } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { LiaLinkedin } from "react-icons/lia";
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import Logo from "../components/img/logo-white.svg";
import styles from "./Footer.css";
import { EmailTextInput } from "./EmailTextInput";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const Footer = ({ className }) => {
  const { t } = useTranslation();

  const alignmentStyle = {
    alignItems: i18n.language === "ar" ? "text-align-right" : "text-align-left",
  };

  return (
    <footer className={`footer ${className}`}>
      <div className="footer-div">
        <div className="head">
          <h1 className="h1-style">{t("HOMEOFPA")} </h1>
          <img src={Logo} alt="core-logo" className="logo1" />
        </div>
        <hr
          className="line"
          style={{
            width: "100%",
            borderTop: "2px solid",
            borderColor: "#808284",
          }}
        />
        <div className="footer-content">
          <div className="footer-div1">
            {/* join the community */}
            <div className="footer-column">
              <h5>{t("NEWS")} </h5>
              <EmailTextInput className="input-style" />
              <p className="heading1">{t("TERMSANDCONDITIONS")}</p>
            </div>
          </div>

          <div className="footer-div2">
            {/* services list */}
            <div className="footer-column">
              <h5 className=" ">{t("SERVICES")}</h5>
              <ul>
                <li>
                  <a
                    href="/entrepreneurship"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("EntrepreneurshipProgram")}
                  </a>
                </li>
                <li>
                  <a
                    href="/corepro"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("VocationalTrainingProgrammes")}
                  </a>
                </li>
                <li>
                  <a
                    href="/workspace"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("WorkSpace")}
                  </a>
                </li>
              </ul>
            </div>
            {/* pages list */}
            <div className="footer-column">
              <h5>{t("PAGES")}</h5>
              <ul>
                <li>
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("Home")}
                  </a>
                </li>
                <li>
                  <a
                    href="/aboutus"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("AboutUs")}
                  </a>
                </li>
                <li>
                  <a
                    href="/experts"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("Experts")}
                  </a>
                </li>
                <li>
                  <a
                    href="/entrepreneurship"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("EntrepreneurshipProgram")}
                  </a>
                </li>
                <li>
                  <a
                    href="/corepro"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("VocationalTrainingProgrammes")}
                  </a>
                </li>
                <li>
                  <a
                    href="/workspace"
                    style={{ textDecoration: "none", color: " #B1B3B4" }}
                  >
                    {t("WorkSpace")}
                  </a>
                </li>
              </ul>
            </div>

            {/* location list */}
            <div className="footer-column">
              <h5>{t("LOCA")}</h5>
              <ul>
                <li>
                  <a
                    style={{ color: " #b1b3b4" }}
                    href="https://www.google.com/maps/place/Core+Istanbul+-+Business+Hub/@41.005821,28.8156411,17z/data=!3m1!4b1!4m6!3m5!1s0x14caa556236cdc8f:0xe9bd0605931411c4!8m2!3d41.005821!4d28.818216!16s%2Fg%2F11p06yx_8k?entry=ttu"
                  >
                    <HiOutlineMapPin style={{ fontSize: "1.5em" }} />
                    <span style={{ verticalAlign: "middle" }}>
                      ,Yenibosna Merkez <br />
                      ,29 Ekim Cd No: 11C D:51 <br />
                      34197 Bahçelievler/İstanbul
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-div3">
        <div className="copyright-Style">{t("ITU")}</div>

        <div style={{ display: "flex", gap: "20px" }}>
          <a
            href="https://www.facebook.com/corebhub/"
            style={{ color: "unset" }}
          >
            <AiOutlineFacebook style={{ fontSize: "2em" }} />
          </a>
          <a
            href="https://www.linkedin.com/company/corebhub/?originalSubdomain=tr"
            style={{ color: "unset" }}
          >
            <LiaLinkedin style={{ fontSize: "2em" }} />
          </a>
          <a
            href="https://www.instagram.com/corebhub/"
            style={{ color: "unset" }}
          >
            <AiOutlineInstagram style={{ fontSize: "2em" }} />
          </a>
          <a
            href="https://www.youtube.com/@corebhub"
            style={{ color: "unset" }}
          >
            <AiOutlineYoutube style={{ fontSize: "2em" }} />
          </a>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FCoreBHub"
            style={{ color: "unset" }}
          >
            <RiTwitterXFill style={{ fontSize: "1.7em" }} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
