import React, { useReducer, useRef } from "react";
import PropTypes from "prop-types";
import "./cards.css";

const PatternCard = ({ property1, title, text, image1, image2, image3 }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    dispatch({ type: "mouse_enter" });

    // Clear any existing timers
    clearTimeout(timerRef.current);

    // Set a timer to transition to the third state after a delay
    timerRef.current = setTimeout(() => {
      dispatch({ type: "task_done" });
    }, 1000); // Change the duration as needed
  };

  const handleMouseLeave = () => {
    dispatch({ type: "mouse_leave" });

    // Clear the timer if the mouse leaves before the transition
    clearTimeout(timerRef.current);
  };

  return (
    <div
      className="pattern-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`frame ${state.property1}`}>
        <div className="text-wrapper-pattern">{title}</div>
        <p className="div">{text}</p>
      </div>
      <img
        className="component-state"
        alt="Component"
        src={
          state.property1 === "mouse-in"
            ? image2
            : state.property1 === "done"
            ? image3
            : image1
        }
        rel="preload"
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case "mouse_enter":
      if (state.property1 === "default") {
        return {
          ...state,
          property1: "mouse-in",
        };
      }
      return state;
    case "mouse_leave":
      if (state.property1 === "mouse-in" || state.property1 === "done") {
        return {
          ...state,
          property1: "default",
        };
      }
      return state;
    case "task_done":
      if (state.property1 === "mouse-in") {
        return {
          ...state,
          property1: "done",
        };
      }
      return state;
    default:
      return state;
  }
}

PatternCard.propTypes = {
  property1: PropTypes.oneOf(["done", "mouse-in", "default"]),
};

export default PatternCard;
