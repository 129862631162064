import "./frameHover.css";

export const FrameHover2 = ({ className, text, text1 }) => {
  return (
    <div className={`frame-hover-2 ${className}`}>
      <div className="div">
        <p className="text-wrapper">{text}</p>
        <div className="text-wrapper-2">{text1}</div>
      </div>
    </div>
  );
};
