import { Faq } from "../components/FAQ";
import Footer from "../components/Footer";
import { FrameHover } from "../components/FrameHover";
import { FrameHover2 } from "../components/FrameHover2";
import IncubationProgram from "../components/IncubationProgram";
import Arrow from "../components/img/arrow-faq.svg";
import Navbar from "../components/Navbar";
import Image from "../components/img/coreproImage.webp";
import logo from "../components/img/logo.png";
import Play from "../components/img/video-img.svg";
import "./entrepreneurshipProgram.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const Entrepreneurship = () => {
  const { t } = useTranslation();
  const html = document.querySelector("html");

  return (
    <div className="entrepreneurship">
      <div className="div-3">
        <div className="overlap-group">
          <div className="frame-21">
            <div className="frame-22">
              <p className="text-wrapper-18">{t("IncubationProgramsRole")}</p>
              <img className="img" alt="Frame" src={logo} />
            </div>
            <div className="frame-23">
              <p className="text-wrapper-19">{t("FirstProgramInTurkey")}</p>
              <div className="frame-24">
                <p className="text-wrapper-20">{t("FirstINTurkeyTitle")}</p>
                <img className="rectangle" alt="Rectangle" src={Image} />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-25">
          <div className="div-26">
            <div className="frame-26">
              <iframe
                className="rectangle-2"
                src="https://www.youtube.com/embed/Vyoz4lykirE?si=ZFa_3_uqqwSVaoy9"
                title="YouTube video player"
                allowfullscreen
              ></iframe>
              {/* <img className="frame-27" alt="Frame" src={Play} /> */}
            </div>
            <div className="frame-28">
              <div className="text-wrapper-21">
                {t("EntrepreneurshipProgrammepage")}
              </div>
              <p className="text-wrapper-22">
                {t("EntrepreneurshipProgrammepageText")}
              </p>
            </div>
          </div>
        </div>
        <div className="frame-29">
          <div className="div-26">
            <div className="frame-26">
              <iframe
                className="rectangle-2"
                src="https://www.youtube.com/embed/Vyoz4lykirE?si=ZFa_3_uqqwSVaoy9"
                title="YouTube video player"
                allowfullscreen
              ></iframe>
              {/* <img className="frame-27" alt="Frame" src={Play} /> */}
            </div>
            <div className="frame-30">
              <div className="text-wrapper-23">{t("SuccessStory")} </div>
              <p className="text-wrapper-24">{t("SuccessStoryText")}</p>
            </div>
          </div>
        </div>
        <div className="frame-31">
          <div className="div-25">
            <div className="text-wrapper-25"> {t("ProgrammeIncludes")}</div>
          </div>
          <div className="frame-32">
            <div className="frame-24">
              <FrameHover2
                divClassName="design-component-instance-node-2"
                text={t("IncubationPrograms")}
                text1="01"
              />
              <FrameHover2 text={t("AccelerationPrograms")} text1="02" />
            </div>
            <div className="frame-24">
              <FrameHover2 text={t("investors")} text1="03" />
              <FrameHover2 text={t("ConferencesAndMeetings")} text1="04" />
            </div>
          </div>
        </div>
        <IncubationProgram className="group" />
        <div className="frame-38">
          <div className="div-25">
            <div className="frame-39">
              <div className="text-wrapper-25">
                {t("IncubationProgramStages")}{" "}
              </div>
              <p className="text-wrapper-32">{t("ReplacedText")}</p>
            </div>
          </div>
        </div>
        <div className="frame-40">
          <div className="div-25">
            <div className="frame-30">
              <div className="text-wrapper-25"> {t("ApplicationStage")} </div>
              <p className="text-wrapper-32">{t("ReplacedText")}</p>
            </div>
          </div>
          <div className="frame-24">
            <FrameHover
              title={t("IncubationProgram1")}
              text={t("ReplacedText")}
              disable={true}
            />
            <FrameHover
              title={t("IncubationProgram2")}
              text={t("ReplacedText")}
              disable={true}
            />
            <FrameHover
              title={t("IncubationProgram3")}
              text={t("ReplacedText")}
              disable={true}
            />
          </div>
        </div>
        <div className="frame-41">
          <div className="div-25">
            <div className="frame-30">
              <div className="text-wrapper-25">
                {t("FrequentlyAskedQuestions")}
              </div>
              <p className="text-wrapper-32">{t("ReplacedText")}</p>
            </div>
          </div>
          <div className="frame-32">
            <Faq
              className="FAQ-instance"
              property1="variant-4"
              title0={t("FAQ1")}
              text0={t("FAQ1Answer")}
              title1={t("FAQ2")}
              text1={t("FAQ2Answer")}
              title2={t("FAQ3")}
              text2={t("FAQ3Answer")}
              title3={t("FAQ4")}
              text3={t("FAQ4Answer")}
              title4={t("FAQ5")}
              text4={t("FAQ5Answer")}
              title5={t("FAQ6")}
              text5={t("FAQ6Answer")}
            />
            <div className="frame-42">
              <div className="frame-43">
                <img className="arrow" src={Arrow} alt="Arrow" />
                <p className="text-wrapper-33"> {t("MoreQ")} </p>
              </div>
            </div>
          </div>
        </div>
        <Footer className="footer" />
      </div>
    </div>
  );
};
