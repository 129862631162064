import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Entrepreneurship } from "./pages/EntrepreneurshipProgram";
import { AboutUs } from "./pages/AboutUs";
import { Experts } from "./pages/Experts";
import { ContactUs } from "./pages/ContactUs";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
  ar: {
    translation: {
      ReplacedText1: "هذا النص مؤقت وسيتم اسبداله في الوقت القريب",
      ReplacedText:
        "تمتلك كور اسطنبول لريادة الأعمال برامج متقدمة متخصصة في التأهيل المهني",
      Card1big: "لا نقدم برامج تقليدية",
      Card1small: "بل رحلات شغف تنقلك نحو تحقيق أحلامك المهنية",
      Card2big: "في عالمنا، نصنع قصص نجاح تتخطى المألوف",
      Card2small: "نمزج بين الشغف والإبداع لنصقل الأفكار ونحولها إلى واقع",
      Card3big: "كور اسطنبول موطن شغفك",
      Card4big: "كور اسطنبول، المركز التركي العربي الأكبر لريادة الأعمال",
      Card5big: "كور ليست مجرد مكان للعمل",
      Card5small: "وإنما مجتمع ريادة الأعمال المتكامل",
      movingText:
        "تمكين الشباب - أسس شغفك - ريادة الأعمال -  موطن الشغف - كور إسطنبول -  اصنع شغفك",
      AboutCoreIstanbul: "عن كور اسطنبول",
      AboutCoreText:
        "كور اسطنبول هي مبادرة شباب عربي في تركيا تهدف إلى دعم ريادة الأعمال العربية، وتقدم خدمات أعمال متكاملة في مساحة 1200 متر مربع بقلب مدينة إسطنبول.",
      OurServices: "ما هي خدماتنا؟",
      OurServicesText:
        "تمتلك كور اسطنبول لريادة الأعمال برنامج متخصص في تطوير مجتمع ريادة الأعمال والشركات الناشئة.",
      EntrepreneurshipProgram: "برنامج ريادة الأعمال",
      EntrepreneurshipProgramText:
        "تمتلك كور اسطنبول لريادة الأعمال برامج متخصصة في تطوير مجتمع ريادة الأعمال والشركات الناشئة.",
      VocationalTrainingProgrammes: "برامج التدريب المهني",
      VocationalTrainingProgrammesText:
        "تنظم كور إسطنبول مجموعة من الورش والفعاليات في عدة مجالات لتمكين الشباب العربي من مهارات العمل في السوق.",
      ServeiceCard1:
        "تنظم كور إسطنبول مجموعة من الورش والفعاليات في عدة مجالات لتمكين الشباب العربي من مهارات العمل في السوق.",

      WorkSpace: "المساحة المشتركة",
      WorkSpaceText:
        "توفر مساحة كور إسطنبول (60) طاولة عمل مع مكاتب خاصة بالإضافة إلى غرف الاجتماعات وقاعات التدريب المجهزة بالكامل.",
      LastestNews: "آخر أخبار كور إسطنبول",
      More: "المزيد",
      Home: "الرئيسية",
      AboutUs: "من نحن",
      Space: "مساحة العمل",
      Experts: "الخبراء",
      MeetExperts: "تعرف على خبرائنا",
      you: " ! أنت",
      name: "اسمك الأول",
      namePlaceholder: "مثال: أحمد",
      email: "بريدك الالكتروني",
      NewsLetterTitle: "نشرة كور إسطنبول",
      NewsLetterText:
        "انضم إلى نشرتنا البريدية لتحصل على أحدث التحديثات والفعاليات من كور إسطنبول! استفد من فرص حصرية للمشاركة في ورش العمل، وتلقى نصائح مفيدة لتعزيز مهاراتك وتحقيق أهدافك. انضم الآن وكن جزءًا من مجتمع الإبداع والتميز",
      yourEmail: "أدخل إيميلك هنا: ",
      Testimon: "قالوا عنا",
      youText: "سجل الان، وكن احد خبراء كور اسطنبول",
      ContactUs: "تواصل معنا",
      ContactUsText:
        "إذا كنت بحاجة إلى مساعدة أو لديك أي أسئلة، فنحن هنا من أجلك",
      yenibosna: "يني بوسنا",
      buses: "الباصات التي تمر بالقرب من كور اسطنبول:",
      coreistanbullocation: "تقع مساحة كور إسطنبول لريادة الأعمال في اسطنبول -",
      Coffee: "لنتناول فنجان قهوة معًا",
      phone: "الهاتف",
      conEmail: "البريد الالكتروني",
      workTime: "أوقات العمل",
      workTimeText: "من الإثنين  إلى الجمعة",
      callus: "اتصل بنا",
      phoneNum: "905526441592+",
      happytohelp: "سنكون سعداء بتواصلك معنا",
      happytohelpText:
        "هل لديك سؤال أو تعليق؟ أرسل لنا رسالة وسنقوم بالرد في غضون 24 ساعة",
      howToHelp: "كيف يمكننا مساعدتك؟",
      send: "إرسال",
      formPhone: "رقم الهاتف",
      formName: "الاسم",
      subject: "الموضوع",

      ExpertsText:
        "كور إسطنبول لريادة الأعمال والاحتضان. هنا، نجمع بين أصحاب الخبرات والمعرفة ورواد الأعمال الطموحين لخلق بيئة تفاعلية تسهم في نمو وتطور المشروعات الناشئة. استكشف مقالاتنا التعليمية، دراسات الحالة الملهمة، والإرشادات العملية التي تم تصميمها خصيصًا لتمكينك من تحقيق أقصى استفادة من رحلتك الريادية.",
      OurVision: "رؤيتنا",
      OurVisionText:
        "مركز لريادة الأعمال العربية في تركيا، يمتلك حاضنات ومسرعات الأعمال وشبكة مستثمرين في عدد من دول الشرق الاوسط وشمال افريقيا.",
      OurMessage: "رسالتنا",
      OurMessageText:
        "المساهمة في خلق شركات ورواد أعمال عرب يدعمون النهضة الاقتصادية لمجتمعاتهم، وتحفيز روح الابتكار والمبادرة الريادية لدى الشباب العربي.",
      OurValues: "قيمنا",
      value1: "الابتكار",
      value1Text:
        "نحن نعتبر الابتكار جوهر جميع أعمالنا، ونسعى دائمًا للتفوق والتطور.",
      value2: "الجودة",
      value2Text: "نحن نسعى لتقديم أفضل الخدمات والمنتجات لعملائنا وشركائنا.",
      value3: "التشاركية",
      value3Text:
        "نحن نؤمن بأهمية التعاون والتشارك مع جميع الشركاء لتحقيق نجاح مشترك ومستدام.",
      value4: "الكفاءة",
      value4Text:
        "نحن نسعى لتحقيق الكفاءة والفعالية في جميع العمليات والخدمات التي نقدمها.",
      value5: "الثقة",
      value5Text:
        "نحن نبني علاقات قوية مبنية على الثقة والنزاهة مع جميع العملاء والشركاء.",
      value6: "المؤسسية",
      value6Text:
        "نحن نتميز بروح المؤسسة والتفاني في العمل كفريق واحد نحو تحقيق أهدافنا المشتركة.",
      Donors: "المانحون",
      BeDonor: "كن أحد المانحين",
      Team: "فريق الشغف",
      TeamMem1: "نضيرة",
      dep1: " موظفة خدمات",
      TeamMem2: "أحمد عصام",
      dep2: "مونتير",
      TeamMem3: "عبدالله خليل",
      dep3: "محاسب",
      TeamMem4: "بلال صبح",
      dep4: " متدرب قسم البرامج",
      TeamMem5: "حمزة الأحمدي",
      dep5: "متدرب قسم البرمجة ",
      TeamMem6: "حبيبة هشام",
      dep6: " مصممة جرافيك",
      TeamMem7: "عمر الحداد ",
      dep7: "منسق قسم ريادة الأعمال",
      TeamMem8: "عبدالله عاشور",
      dep8: "مدير البرامج",
      TeamMem9: "البراء السوداني",
      dep9: "مدير التسويق",
      TeamMem10: "عبد الرحمن العيساوي ",
      dep10: " المدير التنفيذي",
      TeamMem11: "محمد عادل أوغلو",
      dep11: "مدير ريادة الأعمال",
      TeamMem12: "لارا سعد",
      dep12: "منسقة المشاريع والعلاقات العامة",
      TeamMem13: "كرم المولى",
      dep13: "مصور",
      TeamMem14: "مضر غليون",
      dep14: "مدير الاستثمار",
      TeamMem15: "مصطفى حسين",
      dep15: " متدرب تسويق",

      Zero: "نبني جسوراً ",
      One: "للابتكار",
      Two: "للشغف",
      Three: "للنمو",
      Four: "للبداع",

      Album: "البوم الصور",
      coreProSmallText: "تستهدف الشباب التركي - العربي - الاجنبي في تركيا",
      EmpoweringYouth: "تمكين الشباب",
      EmpoweringYouthText1:
        "تعمل كور على تمكين الشباب من مهارات العمل في السوق بمختلف القطاعات ",
      EmpoweringYouthText:
        "يصمم ويقدم هذه البرامج نخبة من المدربين والعاملين في الشركات الكبرى، لبناء طاقات وظيفية قادرة على المساهمة في التنمية الاقتصادية الحديثة",
      SuccessStory: "قصة نجاح",
      SuccessStoryText:
        "نجاح شركة ناشئة احتضنتها كور اسطنبول، قصة ملهمة ومميزة نجاح شركة ناشئة احتضنتها كور اسطنبول، قصة ملهمة ومميزة",
      Training: "مجالات التدريب",
      Markiting: "التسويق الرقمي",
      GraphicDesign: "التصميم الجرافيكي",
      VE: "المونتاج",
      Photography: "التصوير الفوتوغرافي",
      VideoShooting: "تصوير الفيديو",
      Programming: "البرمجة",
      Secretarial: "السكرتارية وإدارة المكاتب",
      ACC: "المحاسبة المالية",
      DataAnalysis: "تحليل البيانات",
      ECom: "التجارة الالكترونية",
      CoreProSteps: "مراحل برنامج التأهيل المهني",
      stage1title: " استقطاب مدربين خبراء في السوق",
      stage1step1: "استقطاب المدربين والخبراء في السوق",
      stage1step2: "إعداد أجندة ومناهج التدريب",
      stage2title: " استقطاب الشباب العربي المستهدف",
      stage2step1: "إعداد معايير القبول",
      stage2step2: "استمارة التقديم",
      stage2step3: "تنفيذ حملات الاستقطاب",
      stage2step4: "تصفية المتدربين",
      stage3title: " تنفيذ البرنامج التدريبي",
      stage3step1: "عقود الالتزام بالبرنامج",
      stage3step2: "سياسات البرنامج",
      stage3step3: "تنفيذ البرنامج",
      stage3step4: "المتابعة والتقييم",
      stage4title: " التوظيف والتشبيك",
      stage4step1: "ربط المميزين بسوق العمل",
      stage4step2: "المتابعة والتقييم والتطوير",
      Applytext: "تقدم بطلبك الآن و اتخذ خطوتك الأولى في بناء مستقبلك",
      ApplyNow: "قدم طلبك",
      Q1: "ما هو برنامج التأهيل المهني ؟",
      Ans1: "برنامج التأهيل المهني هو برنامج تعليمي أو تدريبي يهدف إلى تزويد الأفراد بالمهارات والمعرفة اللازمة لممارسة مهنة معينة بكفاءة. يتم تصميم هذه البرامج لتلبية احتياجات سوق العمل وتطلبات الصناعة، وغالبًا ما يتم تقديمها من خلال مؤسسات تعليمية أو مراكز تدريب مختصة.",
      Q2: "كيف يمكن للشباب الاستفادة من برنامج التأهيل المهني؟",
      Ans2: "يمكن للشباب الاستفادة من برنامج التأهيل المهني من خلال اكتساب المهارات وتحسين فرص العمل والاستعداد للتحديات المهنية وبناء شبكة علاقات وتحديد مسار مهني",
      Q3: "كيف يمكن أن أسجل على برنامج التأهيل المهني ؟",
      Ans3: "يمكنك التسجيل في برنامج التأهيل المهني عن طريق ملء استمارة التسجيل وتقديم الوثائق المطلوبة للمؤسسة المقدمة للبرنامج.",
      Q4: "هل البرنامج مجاني ؟",
      Ans4: "يعتمد ما إذا كان برنامج التأهيل المهني مجانيًا أو يتطلب رسومًا على سياسة المؤسسة المقدمة للبرنامج، فقد يكون بعض البرامج مجانية بالكامل بينما يتطلب البعض الآخر دفع رسوم تسجيل أو تكاليف تدريبية.",
      Q5: "ما هي معايير القبول في البرنامج ؟",
      Ans5: "معايير القبول في برنامج التأهيل المهني تتنوع حسب المؤسسة المقدمة للبرنامج ونوع التخصص المطلوب، وقد تشمل هذه المعايير الحد الأدنى للتعليم، أو الخبرة المهنية المطلوبة، أو اجتياز اختبارات تقييمية، أو مقابلات شخصية.",
      Q6: "هل أستطيع المواكبة بين دراستي أو عملي مع برنامج التأهيل المهني؟",
      Ans6: "نعم، يمكنك في العديد من الحالات المواكبة بين دراستك أو عملك الحالي وبرنامج التأهيل المهني، وذلك حسب ترتيباتك الشخصية ومرونة برنامج التأهيل والجهة المقدمة له. قد توفر بعض المؤسسات برامج التأهيل في أوقات مرنة مثل المسائية أو عبر الإنترنت، مما يتيح لك الموازنة بين الدراسة والعمل.",
      MoreQ: "لديك سؤال آخر ؟ ارسله هنا",
      EntrepreneurshipProgrammepage: "برنامج ريادة الأعمال",
      EntrepreneurshipProgrammepageText:
        "تمتلك كور اسطنبول لريادة الأعمال برامج متخصص في تطوير مجتمع ريادة الأعمال والشركات الناشئة.",
      ProgrammeIncludes: "يتضمن البرنامج",
      AccelerationPrograms:
        "برامج تسريع للشركات الناشئة في السوق للعمل على تطوير عملياتها وتوسعاتها لأخذ مساحات سوقية أكبر، مع ربطها بخبراء ومستثمرين.",
      IncubationPrograms:
        "برامج احتضان للشركات الناشئة تعمل على تحويلها من أفكار إلى شركة قادرة على العمل في السوق وتحقيق الاختراق، مع ربطها بخبراء ومستثمرين.",
      ConferencesAndMeetings:
        "مؤتمرات وملتقيات تجمع الشركات الناشئة بالمستثمرين والخبراء في السوق لبناء علاقات قوية وفرص استثمارية ناجحة.",
      investors:
        " شبكة مستثمرين من داخل وخارج تركيا مستعدة للاستثمار في الشركات الناشئة",
      IncubationProgramsRole:
        "تـؤدي برامج احتضان الشركات الناشئة دوراً ريادياً في تحقيق التمكين الاقتصادي للشباب في العالم العربي، وهي تعتبر اليوم من أهم أدوات تحريك الموارد وربطها بالفرص والاستثمار ومن ثمة التنمية الاقتصادية.",
      FirstINTurkeyTitle: "البرنامج الأول من نوعه في تركيا",
      FirstProgramInTurkey:
        "يعتبر برنامج احتضان الشركات الناشئة الذي تطلقه كور اسطنبول الأول من نوعه في تركيا، والذي يستهدف (20) شركة ناشئة (تركية - عربية) في تركيا، يعمل خلال (6) أشهر على تطويرها على يد خبراء سوق العمل، ثم يربطها بشبكة المستثمرين، ويستمر معها لتحقيق اختراق للسوق والنجاح.",
      IncubationProgramStages: "مراحل برنامج الاحتضان",
      ApplicationStage: "التقديم على الاحتضان",
      ApplicationStageText:
        "تستهدف هذه المرحلة استقطاب المشاريع القابلة للاحتضان عن طريق استمارة الكترونية تساعدنا في التعرف على فكرة المشروع والمؤسسين، ثم يتم اختيار 50 مشروع من المشاريع المتقدمة للانتقال للمرحلة الثانية من عملية دخول حاضنة كور إسطنبول.",
      TrainingCamp: "لمخيم التدريبي",
      TrainingCampText:
        "مخيم تدريبي يمتد ليومين يتضمن تدريب مكثف لإتمام العروض التقديمية لعرضها على لجنة التحكيم من أجل تصفيتها الى 20 مشروع يدخل الى برنامج الاحضان.",
      Incubation: "الاحتضان",
      IncubationText:
        "برنامج احتضان متكامل لستة أشهر يتضمن ورش تدريبة مكثفة تمكن أصحاب المشاريع من إعداد نموذج عملهم التجاري وتهيئتهم للدخول الى السوق.",
      FinalJudgingDay: "يوم التحكيم النهائي",
      FinalJudgingDayText:
        "تقدم المشاريع عروضها التقديمية النهائية امام لجنة التحكيم لإعلان المشاريع الفائزة القابلة للاستثمار.",
      PostIncubation: "ما بعد الاحتضان",
      PostIncubationText:
        "تعمل كور إسطنبول على ربط المشاريع المتخرجة الناجحة بشبكة مستثمريها مع الاستمرار بتقديم الدعم الاستشاري والارشادي للمشاريع.",
      PreviousIncubationPrograms: "برامج الاحتضان السابقة",
      IncubationProgram1: "برنامج الاحتضان الأول",
      IncubationProgram2: "برنامج الاحتضان الثاني",
      IncubationProgram3: "برنامج الاحتضان الثالث",
      FrequentlyAskedQuestions: "الأسئلة الأكثر شيوعاً",
      FAQ1: "ما هو برنامج الاحتضان؟",
      FAQ2: "كيف يمكن للشباب الاستفادة من برنامج الاحتضان؟",
      FAQ3: "كيف يمكن أن أسجل على برنامج الاحتضان؟",
      FAQ4: "هل البرنامج مجاني؟",
      FAQ5: "ما هي معايير القبول في البرنامج؟",
      FAQ6: "هل أستطيع المواكبة بين دراستي أو عملي مع برنامج الاحتضان؟",
      FAQ1Answer:
        "برنامج الاحتضان هو مبادرة تعليمية أو اجتماعية تهدف إلى دعم الأفراد أو الجماعات الهشة أو المحتاجة من خلال توفير الدعم والمساعدة. يمكن أن يكون هذا الدعم عبارة عن توجيه، تدريب، مساعدة مالية، أو أي نوع آخر من الدعم الذي يهدف إلى مساعدة الأفراد على التحسين والتطور في حياتهم أو في مجال معين.",
      FAQ2Answer:
        "الشباب يمكنهم الاستفادة من برنامج الاحتضان من خلال الحصول على مشورة، التدريب، الدعم المالي، بناء الشبكات، والإلهام لتطوير مهاراتهم وتحقيق أهدافهم.",
      FAQ3Answer:
        "يمكنك التسجيل في برنامج الاحتضان عادةً عن طريق الاتصال بالجهة المنظمة للبرنامج مباشرة أو عبر موقع الويب الخاص بهم إذا كان هذا الخيار متاحًا.",
      FAQ4Answer:
        "تختلف سياسات التكلفة لبرامج الاحتضان بناءً على المؤسسة المنظمة والموارد المتاحة لديها. بعض برامج الاحتضان قد تكون مجانية بالكامل للمشاركين، في حين أن البعض الآخر قد يفرض رسوماً رمزية أو تكاليف للمشاركة.",
      FAQ5Answer:
        "معايير القبول في برنامج الاحتضان تشمل الاهتمام والتحفيز، المؤهلات والخبرة، الالتزام، الجدارة، والتوافق مع أهداف البرنامج وقيمه.",
      FAQ6Answer:
        "نعم، غالبًا ما يتيح برنامج الاحتضان مرونة للمشاركين ليتمكنوا من مواكبة دراستهم أو عملهم بجانب المشاركة في البرنامج. قد يتم تنظيم الفعاليات والجلسات بحيث تتناسب مع الجدول الزمني للمشاركين، وقد تكون هناك خيارات للمشاركة عن بعد أو في أوقات مرنة.",
      WorkspacesTitle: "مساحة كور اسطنبول",
      WorkspacesDescription:
        "تمتلك كور اسطنبول مساحة عمل مشتركة مصممة بطريقة إبداعية شبابية، تقع في مركز اسطنبول، وتمتد على مساحة 1200 متر مربع، مجهزة بكافة متطلبات دعم الأعمال.",
      comfortWorkSps: "بيئة عمل مريحة",
      WorkspacesEnvironment:
        " كور اسطنبول هي بيئة مريحة ومحفزة للعمل والانجاز، تضم مساحة تشبيك عالية في قطاع الأعمال، يستطيع الجميع الانضمام لها والاستفادة من خدماتها",
      WorkspacesInclusivity3: "كور إسطنبول هي موطن شغفك.",
      WorkspacesInclusivity2: "أو مشروع ناشئ أو شركة",
      WorkspacesInclusivity1: "سواء كنت صاحب عمل مستقل",

      ExploreCoreIstanbulSpace: "استكشف مساحة كور اسطنبول",
      WhyChooseCoreIstanbulSpace: "لماذا تختار مساحة كور إسطنبول؟",
      NetworkingAndBuildingRelationships: "التشبيك وبناء العلاقات",
      CreativeAndIntegratedWorkEnvironment: "بيئة عمل إبداعية متكاملة",
      ParticipateInEvents: "المشاركة في الفعاليات",
      EasyAccess: "سهولة الوصول",
      SharedWorkspace: "مساحة العمل المشتركة",

      TrainingRooms: "قاعات تدريب",
      CreativeWorkspace:
        "مساحة عمل مشتركة وإبداعية للشركات والأفراد، تضم 60 دسك لبناء الخبرات والعلاقات.",
      CreativeSpaces: "مساحات إبداعية",
      HighSpeedInternet: "انترنت عالي السرعة",
      LaserPrintingServices: "خدمات الطباعة الليزرية",
      HotDrinks: "مشروبات ساخنة",
      MeetingRooms: "غرف اجتماعات",
      MeetingRoomDescription:
        'توفر "كور إسطنبول" (2) غرف اجتماعات في بيئتها الإبداعية، مجهزة بالكامل، ستحظى فيها بالراحة والخصوصية، تتسع ل (4-12) شخص.',
      DisplayScreen: "شاشة عرض",
      Whiteboard: "سبورة",
      TechnicalSupport: "دعم تقني",
      TrainingRoomDescription:
        "قاعات تدريب مخصصة لعقد الفعاليات والمؤتمرات والتدريبات، تتسع ل (40 - 80) شخص.",
      specialOffice: "مكاتب خاصة",
      LogisticalSupport: "دعم لوجستي",
      SpecialOfficeDescription:
        "توفر كور اسطنبول (10) مكاتب، مجهزة بكافة المستلزمات الأساسية لأصحاب المشاريع، تتسع ل (3 - 8) اشخاص",

      BusinessCafe: "مقهى الأعمال",
      CafeDescription:
        'توفر "كور إسطنبول" مساحة ودية للشباب، عن طريق توفيرها كافيه خاص بالمكان، مصمم بطريقة ابداعية، يقدم فيه المشروبات الساخنة والباردة، مع بعض المأكولات السريعة، يضم طاولات ومقاعد تسع 30 شخص.',
      MrMohammedAlAbed: "الأستاذ محمد العابد",
      MrMohammedAlAbedTitle: "مستشار في إدارة المنتجات الرقمية",
      MrMohammedAlAbedDescription:
        "مستشار في إدارة المنتجات الرقمية للعديد من الشركات في تركيا والخليج العربي ودول المغرب العربي، مؤسس سابق لشركة (Payfull) للتحصيل المالي الرقمي التي تعد في قمة الشركات التركية في التحصيل المالي الرقمي،",

      MrAbdelRahmanMasouti: "الأستاذ عبد الرحمن مسوتي",
      MrAbdelRahmanMasoutiTitle: "مصمم ومدرب أكاديمي",
      MrAbdelRahmanMasoutiDescription:
        "مصمم ومدرب أكاديمي معتمد في برامج التصميم والإظهار المعماري والإعلاني بخبرة لأكثر من (10) سنوات، متخصص في تنمية القدرات الإبداعية في التصميم.",

      MrMustafaJamall: "الأستاذ مصطفى جمال",
      MrMustafaJamallTitle: "استشاري في صناعة البرمجيات",
      MrMustafaJamallDescription:
        "استشاري ومهندس أبحاث في كل من DeepGuard و FreedomDev، متخصص في تقديم الاستشارات للمنظمات في صناعة البرمجيات.",

      MsRashaFakhri: "الاستاذة رشا فخري",
      MsRashaFakhriTitle: "مستشارة مالية",
      MsRashaFakhriDescription:
        "مستشارة مالية بخبرة لأكثر من 14 سنة والمديرة التنفيذية وأحد مؤسسي ANGELS BOARD، والمديرة المالية وأحد الشركاء المؤسسين لمجموعة AARAM لأنظمة إدارة العلاقات، والمديرة المالية واحد الأعضاء المؤسسين لمنظمة DIGITAL ISTANBUL. متخصصة في تقديم الاستشارات المالية والإدارية والنمذجة المالية والتحليل المالي ودراسات الجدوى وكتابة خطط العمل وإدارة المخاطر.",

      MrAhmadNajeebArslan: "الأستاذ احمد نجيب أرسلان",
      MrAhmadNajeebArslanTitle: "خبير قضائي",
      MrAhmadNajeebArslanDescription:
        "محامي حاصل على درجة الماجستير في القانون العام من جامعة إسطنبول (2019)، عمل في شركة (Baker McKenzie)، يتمتع بخبرة واسعة النطاق في الدعاوى القضائية الدولية، واتفاقيات التوزيع والوكالة، والمنازعات الناشئة عن هذه الاتفاقيات، وانتهاك القانون، والإجراءات الضريبية، والفساد في المناقصات، قدم العديد من الاستشارات ومثل العديد من العملاء في مختلف القضايا.",

      MrFadiAlIssawi: "الأستاذ فادي العيساوي",
      MrFadiAlIssawiTitle: "مستشار في ريادة الأعمال",
      MrFadiAlIssawiDescription:
        'رائد أعمال وموجه ومرشد ومستشار ومطور في ريادة الأعمال، يمتلك خبرة لأكثر من (15) سنة في التنمية والتمكين الاقتصادية، شغل منصب المدير الإقليمي لأول حاضنة أعمال في فلسطين "حاضنة بيكتي"، وحالياً يدير محفظة أعمال رقمية في إسطنبول – تركيا.',
      MrNourEddineSaleh: "الأستاذ نور الدين صالح",
      MrNourEddineSalehTitle: "خبير في صناعة العلامات التجارية وتصميم الجرافيك",
      MrNourEddineSalehDescription:
        "خبير في صناعة العلامات التجارية وتصميم الجرافيك لأكثر من (14) سنة، مدير ومؤسس (19Agency) المتخصصة في تقديم الحلول الإبداعية الفعالة في صناعة العلامة التجارية.",

      MrMedhatArabi: "الأستاذ مدحت عرابي",
      MrMedhatArabiTitle: "خبير في صناعة البرمجيات",
      MrMedhatArabiDescription:
        "خبير في صناعة البرمجيات، ومدرب متخصص في (Agile)، حاصل على شهادة (Agile) بمستواها الثالث، وهو أيضًا مستشار تطوير برمجيات في منطقة (EMIA).",

      MrMohammedSaidam: "الأستاذ محمد صيدم",
      MrMohammedSaidamTitle: "مهندس برمجيات",
      MrMohammedSaidamDescription:
        "مهندس برمجيات، مدير المنتجات والخدمات اللوجستية في شركة (Loftyservice)، مستشار في التخليص الجمركي والتسليم النهائي.",

      MrHossamAlKurd: "الأستاذ حسام الكرد",
      MrHossamAlKurdTitle: "متخصص في بناء وتطوير المنتجات التقنية",
      MrHossamAlKurdDescription:
        "رائد أعمال، متخصص في بناء وتطوير المنتجات التقنية وربطها بالأعمال لأكثر من 14 سنة، الشريك المؤسس والمدير التنفيذي لمنصة 'عربوست'، أحد مؤسسي أكبر منصة بيع إلكتروني في فلسطين 'لقطات' وعمل كمدير تنفيذي حتى عام 2018.",

      MrMohannadManjad: "الأستاذ مهند منجد",
      MrMohannadManjadTitle: "خبير تسويق",
      MrMohannadManjadDescription:
        "متخصص بالتسويق، والتواصل المؤسسي، والتخطيط الاستراتيجي، استشاري لعدة شركات ومشاريع ناشئة في تركيا والعراق ومصر، مؤسس Founder of Let's Do Marketing.",

      MrMohammedElif: "الأستاذ محمد اليف",
      MrMohammedElifTitle: "خبير تسويق",
      MrMohammedElifDescription:
        "مستشار ومطور أعمال وخبير في التسويق، وهو أحد مؤسسي شركة 'REACH' للاستشارات والتسويق، قام بتوجيه وإرشاد أكثر من 200 شركة ناشئة، وتدريب أكثر من 2000 مشارك في جميع أنحاء العالم.",

      MrRachidHamwi: "الأستاذ رشيد حموي",
      MrRachidHamwiTitle: "مدرب في ريادة الأعمال والتسويق الالكتروني",
      MrRachidHamwiDescription:
        "مدرب ومستشار ومتحدث في مجال ريادة الأعمال والتسويق الالكتروني. ماجستير في الإدارة الاستراتيجية يدرس دبلوم في إدارة المنظمات غير الحكومية، وشريك مؤسس في عدد من المنظمات غير الحكومية في تركيا، مستشار في عدد من مشاريع الاتحاد الأوروبي لريادة الأعمال والابتكار في تركيا.",
      HOMEOFPA: "موطن الشغف",
      LOCA: "العنوان",
      PAGES: "الصفحات",
      SERVICES: "الخدمات",
      NEWS: "ابقى على إطلاع على آخر أخبار كور اسطنبول",
      TERMSANDCONDITIONS:
        "من خلال التسجيل لتلقي رسائل البريد الإلكتروني، فإنك توافق على سياسة الخصوصية الخاصة بنا. نحن نتعامل مع المعلومات الخاصة بك بمسؤولية. إلغاء الاشتراك في أي وقت",
      ITU: "جميع الحقوق محفوظة Core Istanbul-2024",

      Testimonials: "قالوا عنا",

      KuwaitAmbassadorName: " الأستاذ غسان زواوي",
      KuwaitAmbassadorDepartment: "سعادة سفير دولة الكويت",
      KuwaitAmbassadorQuote:
        "أن هذه النقلة مختلفة في تمكين الناس والتخفيف من معاناتهم وهي تجربة رائعة تستحق التوقف عندها والعمل على دعمها وتطويرها بكافة الأشكال.",

      UNAdvisorName: " الأستاذ عبدالله المعتوق",
      UNAdvisorDepartment:
        "مستشار الأمين العام للأمم المتحدة ورئيس مجلس إدارة الهيئة الخيرية العالمية",
      UNAdvisorQuote:
        "جئنا للمشاركة في افتتاح كور إسطنبول لتمكين الشباب اقتصادياً وربطهم بسوق العمل في تركيا ودعم برامج التأهيل الحرفي وتوفير أكبر مساحة للشباب في تركيا.",

      TurkishAdvisorName: " الأستاذ ياسين أقطاي",
      TurkishAdvisorDepartment: "المستشار التركي",
      TurkishAdvisorQuote:
        "هذه المبادرة ليست مخصوصة ومحدودة بتركيا وإسطنبول فقط، ولكن ستكون نموذجاً من نوع العمل والمبادرة على مستوى العالم.",

      CharityChairmanName: " الأستاذ جمال النوري",
      CharityChairmanDepartment:
        "رئيس مجلس الإدارة في جمعية الشيخ عبدالله النوري الخيرية العالمية",
      CharityChairmanQuote:
        "ندعو القطاع الخاص المبادرة لدعم هذا المشروع الرائد لما له من أهمية في توفير فرص العمل والخروج من دائرة الفقر.",

      BusinessmanName1: " الدكتور سعد البراك",
      BusinessmanDepartment1: "رجل الأعمال والمستثمر المخاطر الدولي",
      BusinessmanQuote1:
        "كور إسطنبول عمل عظيم وجبار، هذه مجموعة لا تعرف المستحيل.",

      BusinessmanName2: "الدكتور سعد البراك",
      BusinessmanDepartment2: "رجل الأعمال والمستثمر المخاطر الدولي ",
      BusinessmanQuote2:
        "أقول للشباب: الإيمان والطموح والعمل الجاد والصبر والتعلم هي المنظومة التي تفتح آفاق الكون.",
      new: "جديد",
      CoreLastestNews: "آخر الأخبار",
      MoreNews: "المزيد من الأخبار",
      New: "جديد",
    },
  },
  en: {
    translation: {
      ReplacedText1: "This text is temporary and will be replaced soon",
      ReplacedText:
        "Core Istanbul business hub offers advanced programs specialized in vocational training",
      // Card1big:
      //   "We design innovative, custom-tailored experiences that go beyond traditional programs ",
      Card1big:
        "We design unique, innovative experiences beyond traditional programs",
      // Card1small:
      //   "But passion journeys that take you towards achieving your professional dreams",
      Card1small: " ",
      Card2big:
        "In Core Istanbul, we create a success stories that transcend the ordinary",

      // Card2small:
      //   " Where creativity meets passion, turning concepts into reality ",

      Card2small: " ",
      Card3big: "Core Istanbul, The Home of passion",
      Card4big:
        " Core Istanbul, The largest and most dynamic Turkish-Arab business hub",

      Card5big:
        "Core is more than just a workspace;\n It's a complete business hub community",
      // Card5small: "But a complete business hub community",
      Card5small: " ",
      movingText:
        "Empowering Youth  -  Home of passion  -  Entrepreneurship  -  Build your passion  -  Core Istanbul  -  Create your passion",
      AboutCoreIstanbul: "About Core Istanbul",
      AboutCoreText:
        "Core Istanbul is an initiative by young youth in Turkey aiming to support youth  entrepreneurship. Offering deep business services to achieve an economic empowerment for the youth in our well designed and equipped 1200 sequare meter co-working space in the heart of Istanbul",

      OurServices: "Our Services",
      OurServicesText:
        "Core Istanbul business hub offers programs for developing the entrepreneurship community and startups to transfer ideas into market",

      EntrepreneurshipProgram: "Entrepreneurship Program",
      EntrepreneurshipProgramText:
        "Core Istanbul incubator and accelerator programs organizes a series of workshops and events in various fields to support the  youth with market-ready skills",

      VocationalTrainingProgrammes: "Core Pro",
      VocationalTrainingProgrammesText:
        "Core Istanbul business hub offers Vocational and Technical Training programs aiming to Create and link competent breadwinners able to contribute in the economic empowerment in Türkiye and Arab countries",
      ServeiceCard1:
        "Core Istanbul offers specialized workshops and events designed to equip Arab youth in Turkey with essential market skills, led by experienced professionals from major companies.",
      WorkSpace: "Shared Workspace",
      WorkSpaceText:
        "Core Istanbul's space provides 60 work desks along with private offices, meeting rooms, and fully equipped training halls",
      LastestNews: "Core Istanbul Last News",
      More: "More",
      Home: "Home",
      AboutUs: "About Us",
      Space: "Workspace",
      Experts: "Experts",
      MeetExperts: "Meet Our Experts",
      NewsLetterTitle: "Core newsletter",
      NewsLetterText:
        "Join our newsletter to get the latest updates and events from Core Istanbul! Benefit from exclusive opportunities to participate in workshops, and receive useful tips to enhance your skills and achieve your goals. Join now and be part of the community of creativity and excellence!",

      name: "Your Name",
      email: "Your Email",
      you: "You !",
      namePlaceholder: " ex: Ahmad",
      yourEmail: "Enter Your Email Here:",
      youText: "Register now and become one of Core Istanbul's experts",
      ContactUs: "Contact Us",
      ContactUsText:
        "If you need assistance or have any questions, we are here for you",
      Coffee: "Let's drink a cup of coffee together",
      coreistanbullocation:
        "The Core Istanbul for business hub space is located in Istanbul -",
      yenibosna: "Yenibosna",
      buses: "Buses that pass near Core Istanbul",
      phone: "Phone",
      conEmail: "Email",
      workTime: "Working Hours",
      workTimeText: "Monday to Friday",
      callus: "Call Us",
      phoneNum: "+905526441592",
      happytohelp: "We would be happy to hear from you",
      happytohelpText:
        "Do you have any questions or comments? Send us a message and we will respond within 24 hours",
      howToHelp: "How can we help you?",
      formPhone: "Phone Number",
      send: "Send",
      formName: "Name",
      subject: "Topic",

      Testimon: "Testimonials",
      ExpertsText:
        "Core Istanbul for business hub and Incubation. Here, we bring together experienced professionals and ambitious entrepreneurs to create an interactive environment that fosters the growth and development of startups. Explore our educational articles, inspiring case studies, and practical guides designed to help you make the most of your entrepreneurial journey.",
      OurVision: "Our Vision",
      OurVisionText:
        "A center for young  entrepreneurship in Turkey, with incubators, accelerators, and an investor network across several countries in the Middle East and North Africa.",

      OurMessage: "Our Mission",
      OurMessageText:
        " Contributing to the economical empowerment in Türkiye and Arab countries.",
      OurValues: "Our Values",
      value1: "Innovation",
      value1Text:
        "We consider innovation the core of all our work, always striving for excellence and development.",
      value2: "Quality",
      value2Text:
        "We aim to provide the best services and products to our clients and partners.",
      value3: "Partnership",
      value3Text:
        "We believe in the importance of collaboration and partnership with all stakeholders to achieve mutual and sustainable success.",
      value4: "Efficiency",
      value4Text:
        "We strive to achieve efficiency and effectiveness in all our operations and services.",
      value5: "Trust",
      value5Text:
        "We build strong relationships based on trust and integrity with all our clients and partners.",
      value6: "Institutionalism",
      value6Text:
        "We are distinguished by a spirit of institution and dedication, working as one team towards achieving our common goals.",
      Donors: "Donors",
      BeDonor: "Be one of the donors",
      Team: "Team",
      TeamMem1: "Nazire",
      dep1: "Service Employee",
      TeamMem2: "Ahmed Essam",
      dep2: "Motion Graphics and Video Editor",
      TeamMem3: "Abdullah Khaleel",
      dep3: "Accountant",
      TeamMem4: "Bilal Sobh",
      dep4: "Programs Department Intern",
      TeamMem5: "Hamza Al-Ahmedi",
      dep5: "Software Intern",
      TeamMem6: "Habiba Hisham",
      dep6: "Graphic Designer",
      TeamMem7: "Omar Al-Haddad",
      dep7: "Entrepreneurship Coordinator",
      TeamMem8: "Abdullah Ashour",
      dep8: "Head of Programs",
      TeamMem9: "Albaraa Al-soudani",
      dep9: "Head of Marketing",
      TeamMem10: "Abdulrahman Al-Esawy",
      dep10: "Executive Director",
      TeamMem11: "Muhammed Adiloğlu",
      dep11: "Head of Entrepreneurship ",
      TeamMem12: "Lara Saad",
      dep12: "Projects and PR Assistant",
      TeamMem13: "Karam Almoula",
      dep13: "Photographer",
      TeamMem14: "Mert Galyon",
      dep14: "Investment Associate",
      TeamMem15: "Mustafa Hussein",
      dep15: "Marketing Intern",

      Zero: "Building bridges for ",
      One: "Innovation",
      Two: "Passion",
      Three: "Grouth",
      Four: "Creativity",

      Album: "Photo Album",

      coreProSmallText: "Targeting Turkish - Arab - foreign youth in Turkey",
      EmpoweringYouth: "Empowering Youth",
      EmpoweringYouthText1:
        "Core focuses on empowering youth with market-ready skills across various sectors",
      EmpoweringYouthText:
        "These programs are designed and delivered by expert trainers and professionals from leading companies to build job capacities that contribute to modern economic development",
      SuccessStory: "Success Story",
      SuccessStoryText:
        "A success story of a startup nurtured by Core Istanbul, an inspiring and distinctive story.",
      Training: "Training Fields",
      Markiting: "Digital Marketing",
      GraphicDesign: "Graphic Design",
      VE: "Video Editing",
      Photography: "Photography",
      VideoShooting: "Video Shooting",
      Programming: "Programming",
      Secretarial: "Secretarial and Office Management",
      ACC: "Financial Accounting",
      DataAnalysis: "Data Analysis",
      ECom: "E-commerce",
      CoreProSteps: "Core Pro Stages",

      stage1title: "Recruiting expert trainers in the market",
      stage1step1: "Recruiting trainers and experts in the market",
      stage1step2: "Preparing training agendas and curricula",
      stage2title: " Recruiting targeted Arab youth",
      stage2step1: "Setting acceptance criteria",
      stage2step2: "Application form",
      stage2step3: "Implementing recruitment campaigns",
      stage2step4: "Filtering trainees",
      stage3title: "Implementing the training program",
      stage3step1: "Commitment to the program",
      stage3step2: "Program policies",
      stage3step3: "Implementing the program",
      stage3step4: "Follow-up and evaluation",
      stage4title: " Employment and networking",
      stage4step1: "Connecting outstanding trainees to the job market",
      stage4step2: "Follow-up, evaluation, and development",
      Applytext: "Apply now and take your first step in building your future",
      ApplyNow: "Apply Now",
      Q1: "What is the Vocational Training Program?",
      Ans1: "The Vocational Training Program is an educational or training program designed to equip individuals with the skills and knowledge necessary to competently practice a particular profession. These programs are designed to meet the needs of the labor market and industry requirements, and are often offered through educational institutions or specialized training centers.",
      Q2: "How can young people benefit from the Vocational Training Program?",
      Ans2: "Young people can benefit from the Vocational Training Program by gaining skills, improving job opportunities, preparing for professional challenges, building a network of relationships, and defining a career path.",
      Q3: "How can I register for the Vocational Training Program?",
      Ans3: "You can register for the Vocational Training Program by filling out the registration form and submitting the required documents to the program provider.",
      Q4: "Is the program free?",
      Ans4: "Whether the Vocational Training Program is free or requires fees depends on the policy of the program provider. Some programs may be completely free while others may require registration fees or training costs.",
      Q5: "What are the admission criteria for the program?",
      Ans5: "The admission criteria for the Vocational Training Program vary depending on the program provider and the required specialization, and may include minimum education, required professional experience, passing evaluation tests, or personal interviews.",
      Q6: "Can I balance my studies or work with the Vocational Training Program?",
      Ans6: "Yes, in many cases, you can balance your current studies or work with the Vocational Training Program, depending on your personal arrangements and the flexibility of the program and the provider. Some institutions offer training programs at flexible times, such as evenings or online, allowing you to balance study and work.",
      MoreQ: "Do you have another question? Send it here.",
      EntrepreneurshipProgrammepage: "Entrepreneurship Program",
      EntrepreneurshipProgrammepageText:
        "Core Istanbul Business Hub has specialized programs in developing the entrepreneurship community and startups.",
      ProgrammeIncludes: "The program includes",
      AccelerationPrograms:
        "Acceleration programs for startups in the market to develop their operations and expansions to gain larger market shares, connecting them with experts and investors.",
      IncubationPrograms:
        "Incubation programs for startups that transform them from ideas into market-ready companies capable of achieving breakthroughs, connecting them with experts and investors.",
      ConferencesAndMeetings:
        "Conferences and meetings that bring together startups with investors and market experts to build strong relationships and successful investment opportunities.",
      investors:
        "A network of investors from within and outside Turkey ready to invest in startups",
      IncubationProgramsRole:
        "Incubation programs for startups play a pioneering role in achieving economic empowerment for youth in the Arab world. Today, they are considered among the most important tools for mobilizing resources, linking them with opportunities and investment, and subsequently economic development.",
      FirstINTurkeyTitle: "The first program of its kind in Turkey",
      FirstProgramInTurkey:
        "The incubation program for startups launched by Core Istanbul is the first of its kind in Turkey, targeting (20) startups (Turkish - Arab) in Turkey. Over (6) months, it works on their development by market experts, then connects them with investors and continues with them to achieve market breakthroughs and success.",
      IncubationProgramStages: "Incubation Program Stages",
      ApplicationStage: "Application for Incubation",
      ApplicationStageText:
        "This stage aims to attract incubable projects through an electronic form that helps us understand the project idea and the founders. Then, 50 projects are selected from the submitted ones to advance to the second stage of entering Core Istanbul's incubation.",
      TrainingCamp: "Training Camp",
      TrainingCampText:
        "A two-day training camp includes intensive training to complete the presentations to be shown to the judging panel, in order to filter them down to 20 projects that will enter the incubation program.",
      Incubation: "Incubation",
      IncubationText:
        "A comprehensive six-month incubation program that includes intensive workshops to help project owners prepare their business model and prepare them for market entry.",
      FinalJudgingDay: "Final Judging Day",
      FinalJudgingDayText:
        "Projects present their final presentations to the judging panel to announce the winning, investable projects.",
      PostIncubation: "Post-Incubation",
      PostIncubationText:
        "Core Istanbul connects successful graduating projects with its network of investors while continuing to provide advisory and guidance support.",
      PreviousIncubationPrograms: "Previous Incubation Programs",
      IncubationProgram1: "Incubation Program One",
      IncubationProgram2: "Incubation Program Two",
      IncubationProgram3: "Incubation Program Three",
      FrequentlyAskedQuestions: "Frequently Asked Questions",
      FAQ1: "What is an incubation program?",
      FAQ2: "How can youth benefit from an incubation program?",
      FAQ3: "How can I register for an incubation program?",
      FAQ4: "Is the program free?",
      FAQ5: "What are the admission criteria for the program?",
      FAQ6: "Can I balance between my studies or work with an incubation program?",
      FAQ1Answer:
        "An incubation program is an educational or social initiative aimed at supporting vulnerable or needy individuals or groups by providing support and assistance. This support can include guidance, training, financial aid, or any other type of assistance designed to help individuals improve and develop in their lives or in a specific field.",
      FAQ2Answer:
        "Youth can benefit from an incubation program by receiving advice, training, financial support, networking opportunities, and inspiration to develop their skills and achieve their goals.",
      FAQ3Answer:
        "You can usually register for an incubation program by contacting the organizing body directly or through their website if this option is available.",
      FAQ4Answer:
        "The cost policies for incubation programs vary based on the organizing institution and its available resources. Some incubation programs may be completely free for participants, while others may charge a nominal fee or participation costs.",
      FAQ5Answer:
        "Acceptance criteria for an incubation program include interest and motivation, qualifications and experience, commitment, merit, and alignment with the program's goals and values.",
      FAQ6Answer:
        "Yes, incubation programs often offer flexibility for participants to balance their studies or work alongside participating in the program. Events and sessions may be organized to fit participants' schedules, and there may be options for remote participation or flexible timings.",
      WorkspacesTitle: "Shared Workspace",
      WorkspacesDescription:
        "Core Istanbul features a creatively designed, youth-oriented shared workspace located in the heart of Istanbul. Spanning 1200 square meters, it is equipped with all the necessities to support business needs.",
      comfortWorkSps: "Comfortable work environment",

      WorkspacesEnvironment:
        " Core Istanbul offers a comfortable and motivating space for work and achievement, featuring high networking opportunities in the business sector. Everyone can join and benefit from its services.",
      WorkspacesInclusivity3: "Core Istanbul is the home of your passion.",
      WorkspacesInclusivity2: "A startup or a company",
      WorkspacesInclusivity1: "Whether you are a freelancer",

      ExploreCoreIstanbulSpace: "Explore Core Istanbul Space",
      WhyChooseCoreIstanbulSpace: "Why Choose Core Istanbul Space?",
      NetworkingAndBuildingRelationships:
        "Networking and Building Relationships",
      CreativeAndIntegratedWorkEnvironment:
        "Creative and Integrated Work Environment",
      ParticipateInEvents: "Participate in Events",
      EasyAccess: "Easy Access",
      SharedWorkspace: "Shared Workspace",
      specialOffice: "Private Offices",
      TrainingRooms: "Training Rooms",
      CreativeWorkspace:
        "A shared and creative workspace for companies and individuals, featuring 60 desks for building expertise and relationships.",
      CreativeSpaces: "Creative Spaces",
      HighSpeedInternet: "High-Speed Internet",
      LaserPrintingServices: "Laser Printing Services",
      HotDrinks: "Hot Drinks",
      MeetingRooms: "Meeting Rooms",
      MeetingRoomDescription:
        '"Core Istanbul" offers (2) meeting rooms in its creative environment, fully equipped, providing comfort and privacy, accommodating (4-12) people.',

      DisplayScreen: "Big TV Display Screen",
      Whiteboard: "Whiteboard",
      TechnicalSupport: "Technical Support",
      TrainingRoomDescription:
        "Dedicated training rooms for events, conferences, and training sessions, accommodating (40 - 80) people.",
      LogisticalSupport: "Logistical Support",
      SpecialOfficeDescription:
        "Core Istanbul offers 10 offices, fully equipped with essential supplies for entrepreneurs, accommodating 3 to 8 people",
      BusinessCafe: "Business Cafe",
      CafeDescription:
        '"Core Istanbul" offers a friendly space for young people by providing a specially designed café, serving hot and cold drinks, with some quick snacks, and equipped with tables and seating for 30 people.',
      MrMohammedAlAbed: "Mr. Mohammed Al-Abed",
      MrMohammedAlAbedTitle: "Digital Product Management Consultant",
      MrMohammedAlAbedDescription:
        "Digital Product Management Consultant for many companies in Turkey, the Gulf, and the Maghreb. Former founder of Payfull, a top Turkish digital payment collection company.",

      MrAbdelRahmanMasouti: "Mr. Abdel Rahman Masouti",
      MrAbdelRahmanMasoutiTitle: "Designer and Academic Trainer",
      MrAbdelRahmanMasoutiDescription:
        "Certified designer and academic trainer in architectural and advertising design programs with over (10) years of experience, specializing in developing creative design skills.",

      MrMustafaJamall: "Mr. Mustafa Jamall",
      MrMustafaJamallTitle: "Software Industry Consultant",
      MrMustafaJamallDescription:
        "Consultant and Research Engineer at DeepGuard and FreedomDev, specializing in providing consultancy to organizations in the software industry.",

      MsRashaFakhri: "Ms. Rasha Fakhri",
      MsRashaFakhriTitle: "Financial Consultant",
      MsRashaFakhriDescription:
        "Financial Consultant with over 14 years of experience, Executive Director and co-founder of ANGELS BOARD, Financial Director and co-founder of AARAM Systems for Relationship Management, and Financial Director and founding member of DIGITAL ISTANBUL. Specializes in financial and administrative consulting, financial modeling, financial analysis, feasibility studies, business plan writing, and risk management.",

      MrAhmadNajeebArslan: "Mr. Ahmad Najeeb Arslan",
      MrAhmadNajeebArslanTitle: "Judicial Expert",
      MrAhmadNajeebArslanDescription:
        "Lawyer with a Master's degree in Public Law from Istanbul University (2019), previously worked at Baker McKenzie, with extensive experience in international litigation, distribution and agency agreements, disputes arising from these agreements, legal violations, tax procedures, and bid corruption. Provided numerous consultations and represented many clients in various cases.",

      MrFadiAlIssawi: "Mr. Fadi Al-Issawi",
      MrFadiAlIssawiTitle: "Entrepreneurship Consultant",
      MrFadiAlIssawiDescription:
        "Entrepreneur, mentor, advisor, and developer in entrepreneurship, with over (15) years of experience in economic development and empowerment. Former Regional Manager of the first business incubator in Palestine 'Pikty Incubator,' currently managing a portfolio of digital businesses in Istanbul, Turkey.",
      MrNourEddineSaleh: "Mr. Nour Eddine Saleh",
      MrNourEddineSalehTitle: "Branding and Graphic Design Expert",
      MrNourEddineSalehDescription:
        "Branding and graphic design expert with over (14) years of experience, Director and Founder of (19Agency), specializing in providing effective creative solutions in branding.",

      MrMedhatArabi: "Mr. Medhat Oraby",
      MrMedhatArabiTitle: "Software Industry Expert",
      MrMedhatArabiDescription:
        "Expert in the software industry and a certified (Agile) trainer with level three certification, also a software development consultant in the (EMIA) region.",

      MrMohammedSaidam: "Mr. Mohammed Saidam",
      MrMohammedSaidamTitle: "Software Engineer",
      MrMohammedSaidamDescription:
        "Software engineer, Director of Products and Logistics Services at (Loftyservice), and a consultant in customs clearance and final delivery.",

      MrHossamAlKurd: "Mr. Hossam Al-Kurd",
      MrHossamAlKurdTitle: "Technology Product Development Specialist",
      MrHossamAlKurdDescription:
        "Entrepreneur with over 14 years of experience in building and developing technology products and linking them to businesses. Co-founder and CEO of 'Arabost' platform, and co-founder of 'Lakatat', one of the largest e-commerce platforms in Palestine, serving as CEO until 2018.",

      MrMohannadManjad: "Mr. Mohannad Manjad",
      MrMohannadManjadTitle: "Marketing Expert",
      MrMohannadManjadDescription:
        "Specialized in marketing, corporate communications, and strategic planning. Consultant for various companies and startups in Turkey, Iraq, and Egypt, and founder of 'Let's Do Marketing'.",

      MrMohammedElif: "Mr. Mohammed Elif",
      MrMohammedElifTitle: "Marketing Expert",
      MrMohammedElifDescription:
        "Business consultant and marketing expert, co-founder of 'REACH' consulting and marketing company. He has mentored over 200 startups and trained more than 2000 participants worldwide.",

      MrRachidHamwi: "Mr. Rachid Hamwi",
      MrRachidHamwiTitle: "Entrepreneurship and Digital Marketing Trainer",
      MrRachidHamwiDescription:
        "Trainer, consultant, and speaker in entrepreneurship and digital marketing. Holds a Master's in Strategic Management, studying a diploma in NGO management, co-founder of several NGOs in Turkey, and consultant for various EU projects in entrepreneurship and innovation in Turkey.",
      HOMEOFPA: "Home of Passion",
      LOCA: "Location",
      PAGES: "Pages",
      SERVICES: "Services",
      NEWS: "Stay up to date with the latest news from Core Istanbul",
      TERMSANDCONDITIONS:
        "By registering to receive email messages, you agree to our privacy policy. We handle your information responsibly. Unsubscribe at any time",
      ITU: "All rights reserved Core Istanbul-2024",

      Testimonials: "Testimonials",

      KuwaitAmbassadorName: "Mr. Ghassan Zouawi",
      KuwaitAmbassadorDepartment: "Ambassador of Kuwait",
      KuwaitAmbassadorQuote:
        "This transition is unique in empowering people and alleviating their suffering. It is a remarkable experience that deserves attention and support to develop it in every possible way.",

      UNAdvisorName: "Mr. Abdullah Al-Mutairi",
      UNAdvisorDepartment:
        "Advisor to the Secretary-General of the United Nations and Chairman of the Global Charitable Foundation",
      UNAdvisorQuote:
        "We came to participate in the opening of Core Istanbul to economically empower youth and connect them with the job market in Turkey, support vocational training programs, and provide more opportunities for youth in Turkey.",

      TurkishAdvisorName: "Mr. Yasin Aktaş",
      TurkishAdvisorDepartment: "Turkish Advisor",
      TurkishAdvisorQuote:
        "This initiative is not limited to Turkey and Istanbul only; it will be a model of work and initiative on a global level.",

      CharityChairmanName: "Mr. Jamal Al-Nouri",
      CharityChairmanDepartment:
        "Chairman of the Sheikh Abdullah Al-Nouri Global Charitable Association",
      CharityChairmanQuote:
        "We urge the private sector to support this pioneering project due to its importance in providing job opportunities and breaking the cycle of poverty.",

      BusinessmanName1: "Dr. Saad Al-Barrak",
      BusinessmanDepartment1:
        "International Businessman and Venture Capitalist",
      BusinessmanQuote1:
        "Core Istanbul is a great and monumental work; this group knows no impossibilities.",

      BusinessmanName2: "Dr. Saad Al-Barrak",
      BusinessmanDepartment2:
        "International Businessman and Venture Capitalist",
      BusinessmanQuote2:
        "To the youth: Faith, ambition, hard work, patience, and learning are the system that opens the horizons of the universe.",
      new: "New",
      CoreLastestNews: "Lastest News",
      MoreNews: "More News",
      New: "New",
    },
  },
};

i18n
  .use(LanguageDetector) // add language detector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    debug: true,
    lng: localStorage.getItem("i18nextLng") || "en", // set default language as English
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ["localStorage", "navigator", "htmlTag", "path", "subdomain"],
      caches: ["localStorage"], // save language change in localStorage
    },
  });

export default i18n;
