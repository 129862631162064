import "./labels.css";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export const LabelMoreDetail = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="label-more-detail" onClick={onClick}>
      {t("More")}
    </div>
  );
};
