// primary.jsx
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import "../components/buttons.css";

export const SmallPrimaryButton = ({
  property1,
  className,
  text,
  colorText,
  width,
  height,
  onClick,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      style={{ width: width, height: height }}
      className={`small-primary-button ${state.property1} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      // onClick={() => {
      //   dispatch("click");
      //   onClick(); // Call the onClick function passed as props
      // }}
    >
      <div className={`text-wrapper`} style={{ color: colorText }}>
        {text}
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "hover",
        };
      default:
        return state;
    }
  }

  if (state.property1 === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "default",
        };
      case "click":
        return {
          property1: "focused",
        };
      default:
        return state;
    }
  }

  return state;
}

SmallPrimaryButton.propTypes = {
  property1: PropTypes.oneOf(["disabled", "focused", "hover", "default"]),
  colorText: PropTypes.string,
};

export default SmallPrimaryButton;
