import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import Ellipse1 from "../components/img/Ellipse1.webp";
import Ellipse2 from "../components/img/Ellipse2.webp";
import Ellipse3 from "../components/img/Ellipse3.webp";
import Ellipse4 from "../components/img/Ellipse4.webp";
import Ellipse5 from "../components/img/Ellipse5.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Testimonial } from "./Testimonial";

export function TestimonialsContainer() {
  const { t } = useTranslation();
  const testimonialCards = [
    <Testimonial
      image={Ellipse5}
      text={
        <>
          {t("BusinessmanQuote1")}
          <br />
          {t("BusinessmanQuote2")}
        </>
      }
      name={t("BusinessmanName2")}
      department={t("BusinessmanDepartment2")}
    />,
    <Testimonial
      image={Ellipse4}
      text={t("CharityChairmanQuote")}
      name={t("CharityChairmanName")}
      department={t("CharityChairmanDepartment")}
    />,
    <Testimonial
      image={Ellipse1}
      text={t("KuwaitAmbassadorQuote")}
      name={t("KuwaitAmbassadorName")}
      department={t("KuwaitAmbassadorDepartment")}
    />,
    <Testimonial
      image={Ellipse2}
      text={t("UNAdvisorQuote")}
      name={t("UNAdvisorName")}
      department={t("UNAdvisorDepartment")}
    />,
    <Testimonial
      image={Ellipse3}
      text={t("TurkishAdvisorQuote")}
      name={t("TurkishAdvisorName")}
      department={t("TurkishAdvisorDepartment")}
    />,
  ];

  const cardStyle = {
    padding: "0 15px", // Wrap padding values in quotes
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    rtl: i18n.language === "ar",
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {testimonialCards.map((card, index) => (
          <div className={cardStyle} key={index}>
            {card}
          </div>
        ))}
      </Slider>
    </div>
  );
}
