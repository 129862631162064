import PropTypes from "prop-types";
import { useReducer } from "react";
import "./cards.css";
import SmallSecondaryButton from "../components/SmallSecondaryButton";

export const ServiceCard = ({
  property1,
  title,
  text,
  size,
  disable,
  imagesrc,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  const handleMouseEnter = () => {
    dispatch("mouse_enter");
  };

  const handleMouseLeave = () => {
    dispatch("mouse_leave");
  };

  return (
    <div
      className={`service-card ${size === "donation" ? "donation" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img className="image-service-card" src={imagesrc} alt="Service" />
      <div className={`frame-service-card ${state.property1}`}>
        <div className="title-service-card">{title}</div>
        <p className="p-service-card">{text}</p>
        {disable && <button className="service-button">تبرع</button>}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "hover",
      };
    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
    default:
      return state;
  }
}

ServiceCard.propTypes = {
  property1: PropTypes.oneOf(["hover", "default"]),
};
