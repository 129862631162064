import PropTypes from "prop-types";
import icon from "./img/Group (1).webp";
import "./testimonial.css";
import i18n from "../i18n";

export const Testimonial = ({ text, name, department, image }) => {
  return (
    <div className="testimonial">
      <img className="icon-testimonial" src={icon} alt="Icon" />
      <p className="text-testimonial">{text}</p>
      <div
        className={`frame-testimonial ${
          i18n.language === "en" ? "ltr" : "rtl"
        }`}
      >
        <div className="div-testimonial">
          <div className="name-testimonial">{name}</div>
          <p className="p-testimonial">{department}</p>
        </div>
        <img className="image-testimonial" src={image} alt="Image" />
      </div>
    </div>
  );
};
