import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css"; // Import CSS file for styling
import i18n from "../i18n";

export const Faq = ({
  title0,
  text0,
  title1,
  text1,
  title2,
  text2,
  title3,
  text3,
  title4,
  text4,
  title5,
  text5,
}) => {
  return (
    <Accordion
      className={`custom-accordion ${i18n.language === "ar" ? "rtl" : "ltr"}`}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header className="header">{title0}</Accordion.Header>
        <Accordion.Body>
          <br />
          {text0}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className="header">{title1}</Accordion.Header>
        <Accordion.Body>
          <br />
          {text1}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className="header">{title2}</Accordion.Header>
        <Accordion.Body>
          <br />
          {text2}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header className="header">{title3}</Accordion.Header>
        <Accordion.Body>
          <br />
          {text3}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header className="header">{title4}</Accordion.Header>
        <Accordion.Body>
          <br />
          {text4}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header className="header">{title5}</Accordion.Header>
        <Accordion.Body>
          <br />
          {text5}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Faq;
